import React from 'react';
import '../styles/Projects.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';

class Projects extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            project: 0,
            test: 1,
        }
        this.changeDescsription = this.changeDescsription.bind(this);
        this.changeProject = this.changeProject.bind(this);
    }

    changeDescsription(e){
        this.setState({ project: parseInt(e.target.value) });
    }

    componentDidMount(){
        document.getElementsByClassName('selected-project')[0].checked = true;
    }

    changeProject(value){
        let project = (this.state.project + parseInt(value)) % 4;
        this.setState({ project: project });
    }

    changeProjects(value){
        let project = (this.state.project + ( 3 - parseInt(value))) % 3;

        if (this.state.project === 2 && this.state.test === 1){
            this.setState({ test: 2 });
        } else if (this.state.project === 0 && this.state.test === 2){
            this.setState({ test: 1 });
        } else if ((this.state.project === 0 && this.state.test === 1 && value === 1) || (this.state.project === 2 && this.state.test === 2 && value === 2)){
            console.log();
        } else {
            this.setState({ project: project });
            document.getElementsByClassName('selected-project')[project].checked = true;
        }
    }

    render(){
        const { t } = this.props;

        return (
            <div id='projects'>
                <h1>{t('projects.title')}</h1>
                <div id='projectsBox'>
                    <div id='arrowWraper'>
                        <FontAwesomeIcon icon={faChevronUp} className='projects-icon noselect' onClick={() => {this.changeProjects(1)}} />
                        <div id='projectsWrapper'>
                            <label className='project'>
                                <input type='radio' name='project' value='0' onClick={this.changeDescsription} className='selected-project'/>
                                <span className='background' id='project1'></span>
                                <p className='noselect'>{t('projects.' + (0 + this.state.test) + '.title')}</p>
                            </label>
                            <label className='project'>
                                <input type='radio' name='project' value='1' onClick={this.changeDescsription} className='selected-project'/>
                                <span className='background' id='project2'></span>
                                <p className='noselect'>{t('projects.' + (1 + this.state.test) + '.title')}</p>
                            </label>
                            <label className='project'>
                                <input type='radio' name='project' value='2' onClick={this.changeDescsription} className='selected-project'/>
                                <span className='background' id='project3'></span>
                                <p className='noselect'>{t('projects.' + (2 + this.state.test) + '.title')}</p>
                            </label>
                        </div>
                        <FontAwesomeIcon icon={faChevronDown} className='projects-icon noselect' onClick={() => {this.changeProjects(2)}}/>
                    </div>
                    <p className='projectDescription noselect'>{t('projects.' + (this.state.project + this.state.test) + '.description')}</p>
                </div>
                <div className='mobile-project'>
                    
                    <div className='mobile-project-selector noselect' id='myElement'>
                        <FontAwesomeIcon icon={faChevronLeft} className='projects-icon noselect' onClick={() => {this.changeProject(2)}}/>
                        <Hammer onSwipeLeft={() => {this.changeProject(1)}} onSwipeRight={() => {this.changeProject(3)}}>
                            <h3 className='mobile-project-name'>{t('projects.' + (this.state.project + 1) + '.title')}</h3>
                        </Hammer>
                        <FontAwesomeIcon icon={faChevronRight} className='projects-icon noselect' onClick={() => {this.changeProject(1)}}/>
                    </div>
                    <Hammer onSwipeLeft={() => {this.changeProject(1)}} onSwipeRight={() => {this.changeProject(3)}}>
                        <p className='projectDescription noselect'>{t('projects.' + (this.state.project + 1) + '.description')}</p>
                    </Hammer>
                </div>
            </div>
        )
    }
}
const ProjectsComponent = withTranslation()(Projects);

export default ProjectsComponent;