import React from 'react';
import '../styles/Offer.css';
import Web from '../assets/web.svg';
import AI from '../assets/ai.svg';
import IoT from '../assets/IoT.svg';
import OfferCard from './OfferCard';
import { withTranslation } from 'react-i18next';

class Offer extends React.Component {
    render(){
        const { t } = this.props;
        return (
            <div id='offer'>
                <h1>{t('offer.title')}</h1>
                <div id='offerCards'>
                    <OfferCard img={Web} title='Web' text={t('offer.card1')}/>
                    <OfferCard img={AI} title='AI' text={t('offer.card2')}/>
                    <OfferCard img={IoT} title='IoT' text={t('offer.card3')}/>
                </div>
            </div>
        )
    }
}
const OfferComponent = withTranslation()(Offer);
export default OfferComponent;