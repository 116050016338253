import React from 'react';
import '../styles/Success.css';
import Arrow from './Arrow';

class SuccessCard extends React.Component {
    
    openNewTab(link){
            window.open(link, "_blank")
    }

    render(){
        
        var backgroundStyle = {
            backgroundImage: 'url("' + this.props.img + '")',
            backgroundColor: '#1a1f34',
            backgroundPosition: 'center -20px',
            backgroundSize: '400px',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <div className='success-card' onClick={() => {this.openNewTab(this.props.link)}} style={backgroundStyle}>
                <div className='success-card-text'>
                    <div>
                        <h2 className='success-card-title'>{this.props.title}</h2>
                        <h3 className='success-card-date'>{this.props.date}</h3>
                    </div>                    
                </div>
                
                <div className='succes-card-bottom'>
                    <p className='success-card-description'>{this.props.description}</p>
                    <Arrow class='arrow success-arrow'/>
                </div>
            </div>
        )
    }
}

export default SuccessCard;