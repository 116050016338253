import React from 'react';
import '../styles/Logo.css';

class Wave1 extends React.Component {
    render(){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={this.props.class} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 360.01 285.27">
                <defs>
                    <linearGradient id="linear-gradient-mobile-3" x1="-.109" x2="1.144" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#26367d"/>
                        <stop offset=".5" stopColor="#0a69ae"/>
                        <stop offset="1" stopColor="#12b8d4"/>
                    </linearGradient>
                </defs>
                <g id="wstega2.2.2mobil" transform="translate(0 -10.88)">
                    <path id="Path_1197" fill="url(#linear-gradient-mobile-3)" d="M260.54 228.29c-28.36-23.98-54.64-51.21-80.06-77.55-31.09-32.21-63.23-65.52-99.4-93.1C54.11 37.08 26.95 21.44 0 10.9v1.08c26.74 10.5 53.7 26.04 80.48 46.46 36.11 27.54 68.22 60.82 99.28 93 25.43 26.35 51.74 53.6 80.13 77.62A542.375 542.375 0 0 0 360 296.15v-1.27a541.906 541.906 0 0 1-99.46-66.59z" className="cls-1" data-name="Path 1197"/>
                    <path id="Path_1198" fill="url(#linear-gradient-mobile-3)" d="M265.67 232.58q-2.58-2.13-5.13-4.29c-28.36-23.98-54.64-51.21-80.06-77.55-11.77-12.2-23.94-24.81-36.16-36.93-23.6-23.42-43.7-41.27-63.24-56.17l-.3-.23a389.368 389.368 0 0 0-35.14-23.93A308.876 308.876 0 0 0 7.82 14.06C5.22 12.95 2.61 11.9 0 10.88v1.07c2.48.97 4.96 1.97 7.43 3.02a308.341 308.341 0 0 1 37.7 19.36A389.474 389.474 0 0 1 80.18 58.2l.3.23c19.5 14.87 39.57 32.7 63.14 56.08 12.21 12.11 24.38 24.72 36.15 36.92 25.44 26.35 51.74 53.61 80.13 77.62q2.565 2.16 5.14 4.29A543.666 543.666 0 0 0 360 296.13V295a541.612 541.612 0 0 1-94.33-62.42z" className="cls-1" data-name="Path 1198"/>
                    <path id="Path_1199" fill="url(#linear-gradient-mobile-3)" d="M276.21 226.05q-2.58-1.905-5.11-3.84c-27.75-21.14-53.2-45.17-77.8-68.42-12.04-11.37-24.49-23.13-37.09-34.45-23.1-20.75-43.37-36.95-63.78-50.99l-.31-.21c-11.78-8.09-23.89-15.6-35.98-22.32a402.7 402.7 0 0 0-38.73-18.96Q8.635 23.14 0 20.05v1.06q8.43 3.045 17.02 6.67a402.96 402.96 0 0 1 38.63 18.91c12.06 6.71 24.14 14.2 35.9 22.28l.3.21c20.37 14.01 40.61 30.19 63.68 50.91 12.6 11.31 25.04 23.07 37.08 34.44 24.63 23.26 50.09 47.32 77.88 68.49 1.7 1.29 3.41 2.58 5.12 3.85A545.886 545.886 0 0 0 360 277.95v-1.11a546.321 546.321 0 0 1-83.79-50.79z" data-name="Path 1199"/>
                    <path id="Path_1200" fill="url(#linear-gradient-mobile-3)" d="M286.73 219.52q-2.565-1.68-5.09-3.4c-27.28-18.46-52-39.5-75.92-59.84-12.18-10.37-24.78-21.08-37.66-31.42a632.16 632.16 0 0 0-64.32-45.81l-.31-.19C91.6 71.59 79.21 64.62 66.62 58.15 53.6 51.45 40.27 45.23 27 39.67c-9.5-3.98-18.41-7.4-27-10.36v1.06c8.47 2.93 17.26 6.3 26.62 10.22 13.24 5.55 26.55 11.76 39.55 18.45 12.57 6.46 24.93 13.42 36.75 20.68l.31.19a630.049 630.049 0 0 1 64.22 45.73c12.87 10.33 25.46 21.04 37.64 31.4 23.93 20.36 48.68 41.42 76 59.91 1.69 1.14 3.39 2.28 5.11 3.4a551.491 551.491 0 0 0 73.81 40.4v-1.1a551.242 551.242 0 0 1-73.28-40.13z" data-name="Path 1200"/>
                    <path id="Path_1201" fill="url(#linear-gradient-mobile-3)" d="M297.26 212.98q-2.55-1.47-5.07-2.95c-26.92-15.91-51.04-34.1-74.37-51.7-12.22-9.21-24.85-18.74-37.88-27.96a655.214 655.214 0 0 0-64.86-40.62l-.31-.17c-11.75-6.41-24.42-12.84-37.65-19.1-13.09-6.21-26.73-12.27-40.52-18.01-13.93-5.8-25.6-10.26-36.6-14v1.06c10.89 3.71 22.45 8.14 36.21 13.87 13.78 5.74 27.39 11.79 40.47 17.99 13.21 6.26 25.86 12.68 37.6 19.08l.32.17a654.392 654.392 0 0 1 64.76 40.56c13.03 9.21 25.65 18.73 37.86 27.94 23.35 17.61 47.49 35.82 74.46 51.76q2.52 1.485 5.09 2.96a562.968 562.968 0 0 0 63.24 30.75v-1.08a563.615 563.615 0 0 1-62.75-30.55z" data-name="Path 1201"/>
                    <path id="Path_1202" fill="url(#linear-gradient-mobile-3)" d="M307.78 206.45c-1.7-.83-3.38-1.66-5.05-2.51-26.7-13.47-49.28-28.25-73.18-43.91-12.13-7.95-24.68-16.16-37.75-24.15a728.452 728.452 0 0 0-65.4-35.44l-.31-.15c-11.44-5.47-24.02-11.2-38.48-17.49-12.53-5.46-26.08-11.2-41.42-17.54-12.25-5.05-25.36-10.38-38.32-15.07Q3.85 48.735 0 47.42v1.06c2.46.84 4.97 1.73 7.53 2.65C20.47 55.82 33.57 61.15 45.8 66.2c15.33 6.34 28.88 12.07 41.4 17.53 14.45 6.29 27.02 12.01 38.53 17.52l.24.11a727.611 727.611 0 0 1 65.3 35.39c13.06 7.97 25.6 16.19 37.73 24.13 23.93 15.67 46.53 30.47 73.28 43.96q2.505 1.26 5.07 2.51c15.67 7.64 33.18 15.05 52.66 22.29v-1.07c-19.32-7.18-36.68-14.54-52.23-22.12z" data-name="Path 1202"/>
                    <path id="Path_1203" fill="url(#linear-gradient-mobile-3)" d="M318.3 199.92q-2.535-1.02-5.03-2.06c-26.65-11.11-48.89-23.4-72.43-36.41-11.91-6.58-24.23-13.39-37.18-20.04-19.42-9.98-40.98-19.88-65.92-30.24l-.34-.14c-12.69-5.26-26.22-10.66-39.31-15.89-14.35-5.73-28.38-11.35-42.31-17.06q-4.455-1.83-8.91-3.67c-9.81-4.04-19.95-8.23-29.85-12.07C11 60 5.37 57.92 0 56.04v1.06c5.26 1.85 10.78 3.89 16.66 6.17 9.89 3.84 20.03 8.02 29.83 12.06 2.96 1.22 5.94 2.45 8.91 3.67 13.93 5.72 27.97 11.34 42.32 17.07 13.08 5.22 26.61 10.62 39.3 15.88l.33.14c24.92 10.36 46.46 20.24 65.85 30.21 12.94 6.64 25.25 13.44 37.15 20.02 23.57 13.02 45.83 25.32 72.53 36.46q2.505 1.035 5.04 2.07c13.21 5.31 27.32 10.32 42.07 15.07v-1.05c-14.61-4.71-28.59-9.68-41.69-14.95z" data-name="Path 1203"/>
                    <path id="Path_1204" fill="url(#linear-gradient-mobile-3)" d="M328.82 193.39c-1.69-.54-3.36-1.07-5.01-1.62-26.84-8.83-48.94-18.71-72.33-29.16-11.49-5.14-23.37-10.45-35.97-15.68-17.37-7.23-37.24-14.72-66.45-25.06l-7.14-2.52c-10.72-3.77-21.8-7.68-33.35-11.88-15.9-5.8-30.04-11.23-43.21-16.59-7.66-3.12-15.37-6.37-22.82-9.52q-8.235-3.48-16.39-6.87C16.17 70.35 7.79 67.08 0 64.25v1.06c7.68 2.8 15.94 6.04 25.79 10.1q8.145 3.375 16.38 6.87c7.45 3.15 15.16 6.4 22.83 9.52 13.19 5.37 27.33 10.81 43.25 16.61 11.55 4.21 22.64 8.11 33.36 11.89l7.13 2.51c29.19 10.33 49.05 17.82 66.4 25.04 12.59 5.23 24.46 10.54 35.94 15.67 23.41 10.47 45.53 20.36 72.42 29.2 1.66.54 3.33 1.08 5.03 1.62 9.89 3.13 20.42 6.15 31.48 9.09v-1.03c-10.96-2.91-21.39-5.91-31.19-9.01z" data-name="Path 1204"/>
                    <path id="Path_1205" fill="url(#linear-gradient-mobile-3)" d="M339.33 186.87q-2.52-.585-5-1.17c-27.52-6.6-49.82-14.15-73.43-22.14-10.69-3.62-21.74-7.36-33.54-11.09-13.9-4.41-28.68-8.71-45.81-13.69q-10.155-2.955-21.17-6.18l-.35-.1c-10.97-3.22-25.55-7.56-40.97-12.67-15.61-5.17-30.45-10.6-44.11-16.12-11.61-4.7-23.12-9.77-34.26-14.67l-5.39-2.37C26.92 82.96 17 78.66 7.08 74.71c-2.41-.96-4.76-1.88-7.08-2.76v1.07c2.2.84 4.43 1.71 6.71 2.62 9.91 3.95 19.82 8.24 28.21 11.92l5.39 2.37c11.14 4.91 22.66 9.98 34.29 14.68 13.68 5.54 28.54 10.97 44.17 16.15 15.44 5.11 30.03 9.46 41.01 12.68l.34.1q11.04 3.24 21.18 6.18c17.12 4.98 31.9 9.28 45.78 13.69 11.79 3.73 22.84 7.47 33.52 11.09 23.63 8 45.96 15.56 73.52 22.17q2.475.6 5 1.17c6.64 1.53 13.64 3.03 20.89 4.51v-1.02c-7.19-1.47-14.1-2.95-20.68-4.46z" data-name="Path 1205"/>
                    <path id="Path_1206" fill="url(#linear-gradient-mobile-3)" d="M349.84 180.34c-1.68-.24-3.34-.48-4.98-.73-29.45-4.41-52.88-9.74-77.69-15.38-8.92-2.03-18.14-4.13-27.97-6.26-6.25-1.36-12.75-2.73-19.62-4.17-14.16-2.97-30.21-6.34-47.88-10.52l-.35-.08c-15.29-3.6-28.96-7.22-41.8-11.06-15.79-4.73-30.93-9.99-45.01-15.65-13.69-5.5-26.78-11.51-40.09-17.7l-.71-.33c-9.55-4.43-18.56-8.61-27.53-12.55-5.69-2.5-11.07-4.77-16.21-6.84v1.08c5.02 2.02 10.27 4.24 15.81 6.68 8.96 3.94 17.97 8.12 27.52 12.54l.71.33c13.33 6.19 26.42 12.2 40.14 17.72 14.11 5.67 29.28 10.95 45.09 15.68 12.86 3.85 26.55 7.47 41.86 11.08l.35.08c17.68 4.18 33.74 7.55 47.91 10.53 6.87 1.44 13.36 2.81 19.61 4.17 9.82 2.13 19.04 4.23 27.96 6.25 24.83 5.64 48.28 10.97 77.76 15.39 1.64.25 3.3.49 4.98.73 3.34.48 6.78.95 10.3 1.43v-1.01q-5.205-.705-10.16-1.41z" data-name="Path 1206"/>
                </g>
            </svg>

        )
    }
}

export default Wave1;