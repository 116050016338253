import React from 'react';
import '../styles/NavBar.css';
import Logo from '../components/Logo';
import Arrow from '../components/Arrow';
import Flag from '../assets/EU_flag.png';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class NavBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            futureLang: '',
            path: '',
        }
        this.changeLanguage = this.changeLanguage.bind(this);
    }

    changeLanguage(){
        this.props.changeLang(this.state.futureLang);
        this.setState({ futureLang: this.state.futureLang === 'en' ? 'pl' : 'en',
            path: this.state.path === 'en' ? '' : 'en' });        
    }

    scroll(to, start){
        var element = document.querySelector(to);
        element.scrollIntoView({ behavior: 'smooth', block: start});
    }

    handleClick(to, start){
        this.scroll(to, start);
        this.colapseMenu();
    }

    colapseMenu(){
        document.getElementById('menu').style.display = 'none';
        document.getElementsByClassName('last')[0].style.width = '25px';
    }

    switchMenu(){
        let menu = document.getElementById('menu');
        let menuIcon = document.getElementsByClassName('last')[0];

        if (menu.style.display === 'none'){
            menu.style.display = 'block';
            menuIcon.style.width = '15px';
        } else {
            menu.style.display = 'none';
            menuIcon.style.width = '25px';
        }
    }

    componentDidMount(){
        document.getElementById('menu').style.display = 'none';
        this.setState({ futureLang: this.props.lang === 'en' ? 'pl' : 'en',
            path: this.props.lang === 'en' ? '' : 'en' });
    }

    render(){
        const { t } = this.props;
        return (
            <div id='navbar'>
                <div id='navbarWrapper'>
                    <div className='icons-nav'>
                        <Logo height='70%' id='navbarLogo'  scroll={() => {this.handleClick('#home', 'start')}}/>
                        <div id='EUFlag'>
                            <img src={Flag} alt='EU-flag'/>
                            <p>European Union</p>
                        </div>
                        
                    </div>
                    <ul className='noselect'>
                        <li onClick={() => {this.scroll('#offer', 'center')}}>{t('navbar.stack')}</li>
                        <li onClick={() => {this.scroll('#projects', 'center')}}>{t('navbar.projects')}</li>
                        <li onClick={() => {this.scroll('#success', 'center')}}>{t('navbar.successes')}</li>
                        <li onClick={() => {this.scroll('#about', 'center')}}>{t('navbar.about')}</li>
                        <li onClick={() => {this.scroll('#contact', 'center')}}>{t('navbar.contact')}</li>
                        <Link to={'/' + this.state.path} rel="alternate" hrefLang={this.state.futureLang}>
                            <li onClick={this.changeLanguage} id='language'>{this.state.futureLang}
                                <Arrow class='arrow'/>
                            </li>
                        </Link>
                    </ul>
                </div>
                <div id='mobile-menu' onClick={this.switchMenu}>
                    <div className='memu-icon'></div>
                    <div className='memu-icon'></div>
                    <div className='memu-icon last'></div>
                </div>
            </div>
        )
    }
}
const NavBarComponent = withTranslation()(NavBar);

export default NavBarComponent;