import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class Arrow extends React.Component {
    render(){
        return (
            <div className={this.props.class} onClick={this.props.onClick}>
                <FontAwesomeIcon icon={faChevronRight} className='arrow-icon'/>
            </div>
        )
    }
}

export default Arrow;