import React from 'react';
import '../styles/Offer.css';

class OfferCard extends React.Component {
    render(){
        return (
            <div className='offer-card'>
                <img src={this.props.img} alt='icon' className='offer-card-icon'/>
                <h2 className='card-title'>{this.props.title}</h2>
                <p>{this.props.text}</p>
            </div>
        )
    }
}

export default OfferCard;