import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import App from "./App";
import { useTranslation } from 'react-i18next';

function App2(){

  const { i18n } = useTranslation();

  const changeLanguage2 = (lang) => {
    if (lang === 'pl'){
      i18n.changeLanguage('pl');
      document.documentElement.lang = 'pl';
      window.history.replaceState("object or string", "Page Title 2", "/");
    } else if (lang === 'en') {
      i18n.changeLanguage('en');
      document.documentElement.lang = 'en';
      window.history.replaceState("object or string", "Page Title 2", "/en");
    } 
  }

  const changeLanguage = (lang) => {
    setTimeout(() => {
      changeLanguage2(lang);
    }, 0);
  }

  function loadLang() {
    if (window.location.pathname === '/' && document.getElementById('input').innerText === 'Name*'){
      i18n.changeLanguage('pl');
      document.documentElement.lang = 'pl';
    } else if (window.location.pathname === '/en' && document.getElementById('input').innerText === 'Nazwa*') {
      i18n.changeLanguage('en');
      document.documentElement.lang = 'en';
    }
  }

  return (
    <div>
      <Router>
        <div>
          <Switch>
            <Route path="/en">
              <App lang='en' changeLng={changeLanguage} loadLang={loadLang}/>
            </Route>
            <Route path="/">
              <App lang='pl' changeLng={changeLanguage} loadLang={loadLang}/>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App2;