import React from 'react';
import '../styles/Menu.css';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

class Menu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            futureLang: '',
            path: '',
        }
        this.changeLanguage = this.changeLanguage.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    scroll(to){
        var element = document.querySelector(to);
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
        document.getElementById('menu').style.display = 'none';
    }

    handleClick(to){
        this.colapseMenu();
        this.scroll(to);
    }

    colapseMenu(){
        document.getElementById('menu').style.display = 'none';
        document.getElementsByClassName('last')[0].style.width = '25px';
    }

    changeLanguage(){
        this.props.changeLang(this.state.futureLang);
        this.setState({ futureLang: this.state.futureLang === 'en' ? 'pl' : 'en',
            path: this.state.path === 'en' ? '' : 'en' });        
        this.colapseMenu();
    }

    componentDidMount(){
        document.getElementById('menu').style.display = 'none';
        this.setState({ futureLang: this.props.lang === 'en' ? 'pl' : 'en',
            path: this.props.lang === 'en' ? '' : 'en' });
    }

    render(){
        const { t } = this.props;
        return (
            <ul id='menu'>
                <li onClick={() => {this.handleClick('#offer')}}>{t('navbar.stack')}</li>
                <li onClick={() => {this.handleClick('#projects')}}>{t('navbar.projects')}</li>
                <li onClick={() => {this.handleClick('#success')}}>{t('navbar.successes')}</li>
                <li onClick={() => {this.handleClick('#about')}}>{t('navbar.about')}</li>
                <li onClick={() => {this.handleClick('#contact')}}>{t('navbar.contact')}</li>
                <Link to={'/' + this.state.path} rel='alternate' hrefLang={this.state.futureLang}>
                    <li onClick={this.changeLanguage} id='language'>{this.state.futureLang}</li>
                </Link>
            </ul>
        )
    }
}
const MenuComponent = withTranslation()(Menu);

export default MenuComponent;