import React from 'react';
import '../styles/Logo.css';

class Wave1 extends React.Component {
    render(){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={this.props.class} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 360.01 199.54">
                <defs>
                    <linearGradient id="linear-gradient-mobile-2" x1="-.062" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#26367d"/>
                        <stop offset=".5" stopColor="#0a69ae"/>
                        <stop offset="1" stopColor="#12b8d4"/>
                    </linearGradient>
                </defs>
                <g id="wstega1.2mobil" transform="translate(0 -.23)">
                    <path id="Path_1173" fill="url(#linear-gradient-mobile-2)" d="M0 199.77a947.715 947.715 0 0 0 214.74-93.11A951.344 951.344 0 0 0 360 1.65V.23C256.38 91.26 132.21 159.86 0 198.7z" className="cls-1" data-name="Path 1173"/>
                    <path id="Path_1174" fill="url(#linear-gradient-mobile-2)" d="M229.32 64.98A907.423 907.423 0 0 0 34.81 76.37c-5.56.95-11.17 1.96-16.66 3.02Q9.1 81.115 0 83.09v1.02q9.21-2 18.33-3.75c5.49-1.05 11.09-2.07 16.64-3.01a906.666 906.666 0 0 1 194.29-11.38c40.74 2.16 75.48 6.95 103.39 10.79 10.09 1.39 19.2 2.64 27.34 3.6v-1.01c-8.1-.95-17.16-2.2-27.2-3.58-27.92-3.84-62.68-8.63-103.47-10.79z" data-name="Path 1174"/>
                    <path id="Path_1175" fill="url(#linear-gradient-mobile-2)" d="M296.67 76.81c-23.31-.95-49.72-2.03-79-2.03h-.2c-44.85.02-114.5 2.89-191.56 16.52-5.49.97-10.99 1.99-16.37 3.04-3.16.62-6.35 1.27-9.54 1.93v1.02c3.25-.67 6.51-1.34 9.74-1.97 5.37-1.05 10.87-2.07 16.35-3.04 76.99-13.62 146.57-16.49 191.38-16.5h.2c29.25 0 55.66 1.08 78.96 2.03 24.83 1.01 46.34 1.89 63.37 1.08v-1c-16.99.81-38.5-.07-63.33-1.08z" data-name="Path 1175"/>
                    <path id="Path_1176" fill="url(#linear-gradient-mobile-2)" d="M258.24 82.26c-16.73.65-34.02 1.33-52.63 2.33-66.52 3.6-129.97 10.88-188.59 21.64-5.4.99-10.81 2.02-16.07 3.07-.32.06-.63.13-.94.19v1.02c.38-.08.76-.15 1.14-.23 5.26-1.04 10.66-2.07 16.06-3.06C75.79 96.46 139.2 89.19 205.68 85.59c18.6-1 35.89-1.68 52.61-2.33 37.19-1.45 69.31-2.71 93.84-7.01 2.72-.48 5.34-1 7.88-1.56v-1.02q-3.885.87-8.05 1.6c-24.48 4.28-56.57 5.54-93.72 6.99z" data-name="Path 1176"/>
                    <path id="Path_1177" fill="url(#linear-gradient-mobile-2)" d="M215.68 92.05q-10.74 1.14-21.92 2.35c-71.74 7.79-130.72 16.3-185.63 26.77-2.7.51-5.41 1.04-8.12 1.57v1.02q4.155-.81 8.31-1.61c54.88-10.46 113.85-18.97 185.55-26.76q11.19-1.215 21.92-2.35c50.62-5.39 94.34-10.04 127.37-19.39q8.925-2.52 16.85-5.59v-1.07q-8.055 3.135-17.12 5.7c-32.96 9.32-76.64 13.97-127.21 19.36z" data-name="Path 1177"/>
                    <path id="Path_1178" fill="url(#linear-gradient-mobile-2)" d="M181.9 104.21l-14.14 2.31C116.39 114.93 58.2 124.45 0 135.95v1.02c58.24-11.51 116.5-21.05 167.92-29.46l14.14-2.31c61.79-10.13 112.22-19.07 152.11-34.15A227.541 227.541 0 0 0 360 59.48v-1.14a223.925 223.925 0 0 1-26.19 11.77c-39.8 15.05-90.18 23.98-151.91 34.1z" data-name="Path 1178"/>
                    <path id="Path_1179" fill="url(#linear-gradient-mobile-2)" d="M170.04 114.02c-37.44 8.23-77.13 16.24-119.16 24.72-16.64 3.36-33.75 6.81-50.88 10.31v1.02c17.19-3.51 34.38-6.98 51.08-10.35 42.03-8.48 81.73-16.49 119.18-24.72 53.71-11.81 109.65-25.62 154.92-46.55A288.266 288.266 0 0 0 360 49.5v-1.19a287.491 287.491 0 0 1-35.24 19.23c-45.19 20.89-101.07 34.68-154.72 46.48z" data-name="Path 1179"/>
                    <path id="Path_1180" fill="url(#linear-gradient-mobile-2)" d="M158.18 123.83C114.65 135.88 64.01 148.09 0 161.97V163c64.13-13.9 114.85-26.14 158.45-38.2C226 106.11 276.12 87.38 316.19 65.84A373.576 373.576 0 0 0 360 38.33V37.1a372.7 372.7 0 0 1-44.29 27.87c-40 21.49-90.06 40.2-157.53 58.86z" data-name="Path 1180"/>
                    <path id="Path_1181" fill="url(#linear-gradient-mobile-2)" d="M146.33 133.65C102.27 148.37 53.15 162.12 0 174.62v1.03c53.27-12.53 102.5-26.3 146.65-41.05 64.04-21.4 116.55-44.74 160.54-71.35A502.876 502.876 0 0 0 360 26.58V25.3a501.048 501.048 0 0 1-53.33 37.1c-43.93 26.56-96.37 49.87-160.34 71.25z" data-name="Path 1181"/>
                    <path id="Path_1182" fill="url(#linear-gradient-mobile-2)" d="M134.48 143.46A1188.914 1188.914 0 0 1 0 186.91v1.03a1191.93 1191.93 0 0 0 134.84-43.55c60.74-23.84 115.7-52.02 163.34-83.75A683.211 683.211 0 0 0 360 14.22v-1.31a679.784 679.784 0 0 1-62.37 46.9c-47.58 31.69-102.48 59.84-163.15 83.65z" data-name="Path 1182"/>
                    <path id="Path_1183" fill="url(#linear-gradient-mobile-2)" d="M122.62 153.28A944.959 944.959 0 0 1 0 198.72v1.04a944.229 944.229 0 0 0 123.03-45.57 959.069 959.069 0 0 0 166.14-96.15Q326 31.49 360 1.6V.27q-34.23 30.105-71.41 56.97a959.358 959.358 0 0 1-165.97 96.04z" className="cls-1" data-name="Path 1183"/>
                    <path id="Path_1184" fill="url(#linear-gradient-mobile-2)" d="M122.62 153.28A944.959 944.959 0 0 1 0 198.72v1.04a944.229 944.229 0 0 0 123.03-45.57 959.069 959.069 0 0 0 166.14-96.15Q326 31.49 360 1.6V.27q-34.23 30.105-71.41 56.97a959.358 959.358 0 0 1-165.97 96.04z" className="cls-1" data-name="Path 1184"/>
                </g>
            </svg>

        )
    }
}

export default Wave1;