import React from 'react';
import '../styles/Logo.css';

class WaveMobile1 extends React.Component {
    render(){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={this.props.class} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 360 185">
                <defs>
                    <linearGradient id="linear-gradient-mobile-1" x1="-.062" x2="1" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#26367d"/>
                        <stop offset=".5" stopColor="#0a69ae"/>
                        <stop offset="1" stopColor="#12b8d4"/>
                    </linearGradient>
                </defs>
                <g id="wstega1.2.2mobil" transform="translate(0 -8.08)">
                    <path id="Path_1186" fill="url(#linear-gradient-mobile-1)" d="M347.2 164.77q-6.09-5.1-12.24-10.21c-21.5-17.8-40.12-32.17-61.09-44.45a232.086 232.086 0 0 0-34.68-16.79 172.832 172.832 0 0 0-35.52-9.42 191.477 191.477 0 0 0-40.42-1.17c-10.64.67-20.57 1.81-30.18 2.92l-4.31.49c-11.07 1.26-22.52 2.56-35.29 2.99-16.05.54-29.55-.55-42.47-3.43-13.68-3.05-28.47-8.72-43.94-16.85-2.37-1.23-4.72-2.53-7.06-3.84v1.15q3.27 1.83 6.6 3.59c15.55 8.17 30.42 13.87 44.19 16.94 13.01 2.9 26.58 4 42.72 3.46 12.8-.44 24.27-1.74 35.37-3l4.31-.49c9.59-1.1 19.51-2.24 30.12-2.91a190.814 190.814 0 0 1 40.21 1.17 171.578 171.578 0 0 1 35.31 9.36 231.933 231.933 0 0 1 34.53 16.71c20.91 12.24 39.49 26.58 60.95 44.35q6.165 5.1 12.24 10.2c4.43 3.7 8.91 7.45 13.44 11.19v-1.3q-6.45-5.34-12.79-10.66z" data-name="Path 1186"/>
                    <path id="Path_1187" fill="url(#linear-gradient-mobile-1)" d="M338.02 162.79q-5.445-4.32-10.93-8.66c-17.52-13.82-38.11-29.66-60.79-42.81a254.251 254.251 0 0 0-34.03-16.64 188.8 188.8 0 0 0-34.76-10.13 223.307 223.307 0 0 0-40.02-3.33c-10.73-.08-20.85.27-30.64.6l-5 .17c-10.91.36-22.2.72-34.58.38-10.14-.29-25.51-1.15-41.9-5.26-13.38-3.36-27.88-9.09-43.09-17.03-.76-.4-1.52-.81-2.28-1.22v1.13c.61.32 1.21.65 1.82.97C17.1 68.94 31.67 74.7 45.13 78.07c16.48 4.13 31.93 5 42.11 5.29 12.42.35 23.72-.02 34.64-.38l5.01-.17c9.78-.33 19.88-.67 30.6-.6a223.157 223.157 0 0 1 39.85 3.32 187.814 187.814 0 0 1 34.58 10.07 254.15 254.15 0 0 1 33.9 16.58c22.62 13.11 43.17 28.93 60.67 42.73q5.49 4.32 10.93 8.66c7.4 5.88 14.93 11.86 22.6 17.75v-1.26c-7.48-5.73-14.8-11.55-22-17.27z" data-name="Path 1187"/>
                    <path id="Path_1188" fill="url(#linear-gradient-mobile-1)" d="M328.43 160.62q-4.59-3.45-9.2-6.92c-18.56-13.92-38.52-28.56-60.48-41.17a285.3 285.3 0 0 0-33.38-16.51 212.7 212.7 0 0 0-34-10.83 272.5 272.5 0 0 0-39.62-5.49c-10.88-.83-21.23-1.31-31.24-1.77l-5.86-.27c-10.66-.49-21.68-1.01-33.58-2.08-11.04-1.01-25.7-2.72-41.32-7.08A220.407 220.407 0 0 1 0 52.54v1.12a220.565 220.565 0 0 0 39.46 15.79c15.69 4.39 30.42 6.11 41.5 7.12 11.93 1.08 22.96 1.59 33.63 2.09l5.86.27c10 .46 20.35.93 31.21 1.76a272.772 272.772 0 0 1 39.48 5.47 212.732 212.732 0 0 1 33.84 10.78 284.12 284.12 0 0 1 33.27 16.45c21.92 12.58 41.85 27.21 60.38 41.11q4.62 3.465 9.2 6.92c10.47 7.88 21.18 15.94 32.17 23.74v-1.22c-10.78-7.67-21.29-15.58-31.57-23.32z" data-name="Path 1188"/>
                    <path id="Path_1189" fill="url(#linear-gradient-mobile-1)" d="M318.27 158.18l-6.9-4.92c-19.66-13.99-39.06-27.54-60.18-39.54a324.069 324.069 0 0 0-32.74-16.36 246.923 246.923 0 0 0-33.24-11.54 341.349 341.349 0 0 0-39.23-7.65c-11.18-1.63-21.92-2.96-32.31-4.24l-6.73-.83c-10.25-1.27-20.84-2.57-32.11-4.31a280.167 280.167 0 0 1-40.74-8.91A246.562 246.562 0 0 1 0 46.12v1.11a244.958 244.958 0 0 0 33.8 13.61 282.043 282.043 0 0 0 40.88 8.95c11.28 1.73 21.88 3.04 32.14 4.31l6.73.83c10.38 1.28 21.12 2.61 32.28 4.24a340.063 340.063 0 0 1 39.11 7.62 245.035 245.035 0 0 1 33.11 11.5 323.032 323.032 0 0 1 32.63 16.31c21.08 11.98 40.45 25.51 60.1 39.48l6.9 4.92c13.7 9.77 27.8 19.8 42.31 29.25v-1.19c-14.29-9.34-28.2-19.24-41.72-28.88z" data-name="Path 1189"/>
                    <path id="Path_1190" fill="url(#linear-gradient-mobile-1)" d="M307.2 155.32l-3.7-2.49c-21.65-14.57-39.8-26.61-59.88-37.9-11.03-6.2-21.83-11.66-32.09-16.22a295.536 295.536 0 0 0-32.48-12.25c-14.11-4.37-27.44-7.35-38.83-9.8-11.75-2.53-23.13-4.8-34.14-7l-7.63-1.52c-9.57-1.9-19.47-3.87-29.83-6.13a358.945 358.945 0 0 1-40.16-10.74A281.951 281.951 0 0 1 0 39.61v1.1a281.569 281.569 0 0 0 28.14 11.5 357.759 357.759 0 0 0 40.27 10.77c10.36 2.25 20.27 4.22 29.85 6.13l7.63 1.52c11.01 2.2 22.39 4.47 34.13 7 11.37 2.45 24.68 5.42 38.75 9.78a293.552 293.552 0 0 1 32.37 12.21c10.24 4.55 21 9.99 32.01 16.18 20.04 11.27 38.18 23.3 59.81 37.86l3.7 2.49c17.22 11.59 35.02 23.56 53.36 34.47v-1.17c-18.15-10.8-35.77-22.65-52.82-34.13z" data-name="Path 1190"/>
                    <path id="Path_1191" fill="url(#linear-gradient-mobile-1)" d="M354.17 187.99c-20.35-11.35-39.76-23.67-58.53-35.59l-1.94-1.23c-18.54-11.77-37.71-23.95-57.63-35.03-10.87-6.05-21.45-11.46-31.44-16.08-10.51-4.86-21.19-9.22-31.73-12.96-13.45-4.77-26.36-8.52-38.44-11.95-12.06-3.43-23.32-6.53-35.75-9.93q-5.145-1.41-10.34-2.82c-8.38-2.28-17.05-4.64-25.96-7.15-11.76-3.31-25.48-7.29-39.58-12.57-7.2-2.71-14.84-5.93-22.83-9.61v1.1c7.86 3.61 15.39 6.77 22.47 9.42 14.14 5.29 27.88 9.28 39.66 12.6 8.92 2.51 17.58 4.87 25.97 7.16q5.205 1.41 10.34 2.82c12.42 3.4 23.69 6.5 35.74 9.93s24.95 7.18 38.38 11.94c10.51 3.73 21.16 8.08 31.64 12.92 9.97 4.61 20.52 10.01 31.38 16.05 19.89 11.07 39.05 23.24 57.58 35l1.94 1.23c18.78 11.92 38.2 24.25 58.58 35.62 2.13 1.19 4.23 2.34 6.32 3.47v-1.14q-2.88-1.56-5.83-3.2z" data-name="Path 1191"/>
                    <path id="Path_1192" fill="url(#linear-gradient-mobile-1)" d="M346.56 185.45c-20.53-10.76-40.3-22.44-58.8-33.49l-8.25-4.94c-16.52-9.89-33.6-20.12-51.02-29.69-10.89-5.99-20.97-11.21-30.79-15.94-10.24-4.93-20.66-9.53-30.97-13.67-12.71-5.1-24.75-9.41-38.04-14.11-10.62-3.76-21.73-7.64-36.03-12.6q-9.12-3.165-18.37-6.33c-5.95-2.05-11.97-4.12-18.11-6.24-13.07-4.53-25.89-9.04-39-14.4C11.66 31.79 5.95 29.3 0 26.57v1.1q8.715 3.99 16.81 7.31c13.14 5.37 25.97 9.89 39.05 14.42 6.14 2.13 12.16 4.2 18.11 6.24 6.18 2.13 12.28 4.22 18.36 6.33 14.29 4.95 25.4 8.84 36.02 12.59 13.28 4.7 25.31 9 38 14.09 10.29 4.13 20.69 8.72 30.91 13.64 9.81 4.72 19.87 9.93 30.75 15.92 17.4 9.57 34.47 19.79 50.99 29.67l8.25 4.94c18.5 11.05 38.29 22.74 58.84 33.51 4.76 2.49 9.38 4.84 13.9 7.07v-1.12c-4.37-2.15-8.83-4.42-13.43-6.83z" data-name="Path 1192"/>
                    <path id="Path_1193" fill="url(#linear-gradient-mobile-1)" d="M338.96 182.91c-21.38-10.49-41.85-21.75-59.06-31.38q-9.555-5.355-19.15-10.78c-12.97-7.33-26.39-14.91-39.82-22.21-10.83-5.89-20.69-11.05-30.15-15.8-9.98-5-20.14-9.84-30.21-14.37-12.83-5.78-25.4-11.09-37.65-16.26-13.07-5.52-24.95-10.51-36.31-15.26-11.46-4.79-22.94-9.53-36.65-15.17l-1.73-.71c-11.9-4.9-24.2-9.96-36.69-15.52Q5.975 22.96 0 20.16v1.1q5.76 2.685 11.15 5.09c12.5 5.56 24.81 10.63 36.71 15.53l1.73.71c13.71 5.64 25.19 10.38 36.64 15.17 11.36 4.75 23.24 9.74 36.4 15.3 12.15 5.13 24.72 10.44 37.54 16.21 10.06 4.53 20.21 9.36 30.17 14.35 9.45 4.74 19.3 9.9 30.12 15.78 13.42 7.3 26.84 14.87 39.81 22.2q9.6 5.43 19.15 10.79c17.22 9.64 37.71 20.91 59.11 31.41 7.43 3.65 14.55 6.99 21.48 10.1v-1.1c-6.8-3.04-13.77-6.32-21.05-9.89z" data-name="Path 1193"/>
                    <path id="Path_1194" fill="url(#linear-gradient-mobile-1)" d="M331.36 180.38c-17.99-8.23-36.84-17.53-59.32-29.28-14.55-7.61-29.34-15.57-43.64-23.27q-7.5-4.035-15.03-8.08c-11.45-6.15-20.82-11.12-29.5-15.66-9.83-5.14-19.74-10.21-29.45-15.08-11.52-5.78-23.03-11.43-34.16-16.89l-3.1-1.52c-12.27-6.03-24.41-11.99-36.59-17.92C68 46.54 55.96 40.73 43.75 34.9q-6.18-2.955-12.44-5.92c-8.29-3.94-16.86-8.02-25.39-12.14-1.97-.96-3.95-1.92-5.92-2.88v1.11q2.745 1.335 5.48 2.66c8.54 4.13 17.11 8.2 25.4 12.15q6.255 2.97 12.44 5.92c12.21 5.83 24.25 11.64 36.81 17.77 12.18 5.94 24.32 11.9 36.59 17.92l3.1 1.52c11.13 5.46 22.63 11.11 34.15 16.89 9.7 4.86 19.61 9.93 29.44 15.07 8.67 4.53 18.04 9.51 29.49 15.65q7.53 4.035 15.02 8.08c14.3 7.71 29.09 15.67 43.65 23.28 22.5 11.76 41.36 21.07 59.37 29.3 10.09 4.62 19.68 8.78 29.06 12.63v-1.08c-9.25-3.79-18.7-7.9-28.64-12.45z" data-name="Path 1194"/>
                    <path id="Path_1195" fill="url(#linear-gradient-mobile-1)" d="M323.75 177.85c-19.23-8.15-38.72-17.04-59.58-27.18-18.31-8.9-37.4-18.62-58.36-29.72-9.15-4.85-18.59-9.92-28.85-15.51-8.78-4.78-18.17-9.94-28.7-15.78-12.09-6.7-23.85-13.28-36.88-20.57l-3.17-1.78q-16.845-9.42-33.7-18.81C60.46 40.68 48.7 34.2 37.52 28.12 24.17 20.86 11.98 14.35.28 8.23c-.09-.05-.19-.1-.28-.15v1.13C11.64 15.3 23.76 21.78 37.05 29c11.18 6.08 22.93 12.55 36.98 20.36q16.86 9.375 33.7 18.81l3.17 1.78c13.03 7.29 24.79 13.87 36.88 20.57 10.53 5.84 19.92 11 28.7 15.79 10.27 5.59 19.71 10.67 28.86 15.52 20.97 11.11 40.07 20.84 58.39 29.74 20.88 10.14 40.38 19.04 59.63 27.2 12.35 5.23 24.66 10.17 36.64 14.72v-1.07c-11.86-4.51-24.04-9.4-36.25-14.57z" className="cls-1" data-name="Path 1195"/>
                </g>
            </svg>

            // <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" className={this.props.class} viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m133 43.002-123 213 123 213h246.001l123.001-213-123.001-213z" fill="#ffda8f"/><path d="m182.202 381.644-121.58-126.72 103.188-160.723 168.998 26.638 47.527 130.008-30.9 164.801z" fill="#b8ecbc"/><g><circle cx="442.547" cy="358.96" r="10"/><path d="m510.657 251-123-213c-1.78-3.08-5.1-5-8.66-5h-246.001c-3.56 0-6.87 1.92-8.66 5l-123.001 213c-1.78 3.08-1.78 6.92 0 10l123.001 213c1.78 3.09 5.1 5 8.66 5h246.001c3.57 0 6.88-1.91 8.66-5l41.05-71.07c2.75-4.78 1.11-10.91-3.66-13.66-1.52-.88-3.25-1.34-4.99-1.34-3.57 0-6.89 1.91-8.67 5l-32.387 56.073-19.359-33.524 28.026-149.478h97.013l-28.294 48.988c-1.33 2.31-1.69 5.01-1 7.59s2.35 4.73 4.66 6.07c1.52.88 3.25 1.34 4.99 1.34 3.57 0 6.89-1.92 8.67-5 .01 0 .01-.01.01-.01l36.94-63.98c1.792-3.079 1.792-6.919.001-9.999zm-271.979 15.001-57.701 99.922-95.869-99.922zm-160.444-20 84.469-131.567 75.975 131.567zm177.767 29.997 72.264 125.14-129.342-26.299zm-74.424-168.874 136.43 21.505-62.006 107.375zm-12.863-22.274-18.393-31.85h211.359l-32.957 57.071zm-35.713-21.85 18.448 31.946-96.982 151.055h-27.148zm-105.68 203.001h30.071l113.172 117.956-37.561 65.045zm334.357 192.999h-211.355l38.053-65.896 153.243 31.159zm-17.398-70.121-70.958-122.877h93.996zm-70.957-142.878 57.924-100.308 36.669 100.308zm70.964-122.888 34.714-60.114 105.682 183.003h-95.473z"/></g></g></svg>

        )
    }
}

export default WaveMobile1;