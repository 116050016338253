import React from 'react';
import '../styles/About.css';

class SuccessCard extends React.Component {
    render(){

        return (
            <div className='about-card'>
                <img src={this.props.img} alt='team' className='team-img'/>
                <div className='team-top'>
                   <h2 className='team-name'>{this.props.name}</h2>
                <h3 className='team-position'>{this.props.position}</h3> 
                </div>
                
                <p className='team-description'>{this.props.description}</p>
            </div>
        )
    }
}

export default SuccessCard;