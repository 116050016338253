import React from 'react';
import '../styles/Contact.css';
import Logo from '../components/Logo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { withTranslation } from 'react-i18next';
import axios from 'axios';

const API_PATH = 'https://neuralbit.pl/send-script.php';

class Contact extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            error: false,
            success: false,
        }
        this.send = this.send.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }
    openNewTab(link){
        window.open(link, "_blank");
    }

    send = e => {
        e.preventDefault();
        
        let name = this.state.name;
        let email = this.state.email;
        let message = this.state.message;


        if (this.validateForm(name, email, message)){
            let form = new FormData();
            form.append('name', name);
            form.append('email', email);
            form.append('message', message);

            axios({
                method: 'POST',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data: form
            })
            .then(result => {
                if (result.data.status === "ok"){
                    this.setState({ error: false, success: true, name: '', email: '', message: '' });
                } else {
                    this.setState({ error: true, success: false });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ error: true, success: false });
            });
        };
    };

    validateForm(name, email, message){
        let status = true;

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        let nameElement = document.getElementById("input");
        let emailElement = document.getElementById("input2");
        let messageElement = document.getElementById("input3");

        if (name.length > 1){
            nameElement.style.color = 'white';
        } else {
            nameElement.style.color = 'red';
            status = false;
        }
        
        if (re.test(String(email).toLowerCase())){
            emailElement.style.color = 'white';
        } else {
            emailElement.style.color = 'red';
            status = false;
        }
        
        if (message.length > 1){
            messageElement.style.color = 'white';
        } else {
            messageElement.style.color = 'red';
            status = false;
        }

        return status;
    }

    render(){
        const { t } = this.props;
        return (
            <div id='contact'>
                <h1>{t('contact.title')}</h1>
                <div id='contactWrapper'>
                    <div id='contactData'>
                        <Logo width='270' id='contactLogo'/>
                        <div id='companyData'>
                            <span id='email' className='comapny-data'>contact@neuralbit.pl</span>
                            <span className='comapny-data'>Neuralbit Technologies Sp. z o. o.</span>
                            <span className='comapny-data'>os. Centrum B 7, 31-927 Kraków</span>
                            <span className='comapny-data'>NIP: 6783169993</span>
                            <span className='comapny-data'>KRS: 0000694761</span>
                            <div id='socialMedia'>
                                <span className='icon-wrapper' onClick={() => {this.openNewTab('https://www.facebook.com/neuralbit/')}}>
                                    <FontAwesomeIcon icon={faFacebookF} className='social-media-icon'/>
                                </span>
                                <span className='icon-wrapper' onClick={() => {this.openNewTab('https://www.linkedin.com/company/neuralbit/about/')}}>
                                    <FontAwesomeIcon icon={faLinkedinIn} className='social-media-icon'/>
                                </span>
                            </div>
                        </div>
                    </div>
                    <form className="form" id="contactForm" method="post" noValidate action="http://serwer2045542.home.pl/send-script.php">
                        <label className='input-description' id='input' htmlFor='name'>{t('contact.name')}*</label>
                        <input
                            type='text'
                            required
                            className='contact-input'
                            name='name'
                            onChange={e => this.setState({ name: e.target.value })}
                            value={this.state.name}
                            id='name'/>
                        
                        <label className='input-description' id='input2' htmlFor='email'>{t('contact.email')}*</label>
                        <input
                            type='email'
                            required
                            className='contact-input'
                            name='email'
                            onChange={e => this.setState({ email: e.target.value })}
                            value={this.state.email}
                            id='mail'/>
                        
                        <label className='input-description' id='input3' htmlFor='message'>{t('contact.message')}*</label>
                        <textarea
                            name="message"
                            required
                            rows="10"
                            cols="30"
                            className='contact-input message'
                            onChange={e => this.setState({ message: e.target.value })}
                            value={this.state.message} 
                            id='message'/>
                        
                        <br/>
                        {this.state.error && <div className='feedback error'>{t('contact.error')}</div>}
                        {this.state.success && <div className='feedback success'>{t('contact.success')}</div>}
                        <button type='submit' className='button contact-button' onClick={this.send}>{t('contact.button')}</button>
                    </form>
                </div>
                
            </div>
        )
    }
}
const ContactComponent = withTranslation()(Contact);
export default ContactComponent;