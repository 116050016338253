import React from 'react';
import Footer from '../assets/infoFooter.png';
import '../styles/Footer.css';

class InfoFooter extends React.Component {
    render(){

        return (
            <div className='infoFooter'>
                <img src={Footer} alt='European Union'/>
            </div>
        )
    }
}

export default InfoFooter;