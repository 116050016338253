import React from 'react';
import '../styles/Success.css';
import SuccessCard from './SuccessCard';
import Croatia from '../assets/Chorwacja2.jpg';
import GovTech from '../assets/GovTech2.jpg';
import HackYeah from '../assets/HackYeah2.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Arrow from './Arrow';
import { withTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';

class Success extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            img: [GovTech, Croatia, HackYeah],
            success: 0,
            successes: [this.props.description1, this.props.description2, this.props.description3],
            titles: [this.props.title1 + " " + this.props.date1, this.props.title2 + " " + this.props.date2, this.props.title3 + " " + this.props.date3],
            links: ['https://www.gov.pl/web/finanse/aplikacja-do-ochrony-polskich-granic-wybrana-w-konkursie-minfintech',
                'https://www.t.ht.hr/en/corporate-social-responsibility/activities/4456/International-HT-challenge-Solution-by-high-school-students-from-Velika-Gorica-among-the-best-ideas-for-saving-Croatian-forests.html?fbclid=IwAR1GRhH-6ndOcUjeZ1bNPgHvGS5egue07Cd7Y0MpELD-aKtKlB2BS-RG-Ic',
                'https://www.krakow.pl/aktualnosci/214769,32,komunikat,hackyeah___najwiekszy_w_europie_stacjonarny_hackathaton_juz_za_nami_.html']
        }
    }

    changeProject(value){
        let success = (this.state.success + parseInt(value)) % 3;
        this.setState({ success: success });
    }

    openNewTab(link){
        window.open(link, "_blank");
    }

    

    render(){

        var backgroundStyle = {
            backgroundImage: 'url("' + this.state.img[this.state.success] + '")',
            backgroundColor: '#1a1f34',
            backgroundPosition: 'center -20px',
            backgroundSize: '80vw',
            backgroundRepeat: 'no-repeat',
        };

        const { t } = this.props;
        return (
            <div id='success'>
                <h1>{t('successes.title')}</h1>
                <div id='successesCards'>
                    <SuccessCard 
                        img={GovTech} 
                        title={t('successes.1.title')} 
                        date={t('successes.1.date')} 
                        id='successCard1'
                        link={this.state.links[0]} 
                        description={t('successes.1.description')}/>
                    <SuccessCard 
                        img={Croatia} 
                        title={t('successes.2.title')}
                        date={t('successes.2.date')} 
                        id='successCard2' 
                        link={this.state.links[1]}
                        description={t('successes.2.description')}/>
                    <SuccessCard 
                        img={HackYeah} 
                        title={t('successes.3.title')}
                        date={t('successes.3.date')} 
                        id='successCard3' 
                        link={this.state.links[2]}
                        description={t('successes.3.description')}/>
                </div>
                <div className='mobile-successes' style={backgroundStyle}>
                    <div className='mobile-success-header'>
                        <FontAwesomeIcon icon={faChevronLeft} onClick={() => {this.changeProject(2)}} className='projects-icon'/>
                        <Hammer onSwipeLeft={() => {this.changeProject(2)}} onSwipeRight={() => {this.changeProject(1)}}>
                            <h3 className='mobile-success-name'>{t('successes.' + (this.state.success + 1) + '.title')}</h3>
                        </Hammer>
                        <FontAwesomeIcon icon={faChevronRight} onClick={() => {this.changeProject(1)}} className='projects-icon'/>
                    </div>
                    <Hammer onSwipeLeft={() => {this.changeProject(2)}} onSwipeRight={() => {this.changeProject(1)}}>
                        <p className='successDescription'>{t('successes.' + (this.state.success + 1) + '.description')}</p>
                    </Hammer>
                        <Arrow class='arrow success-arrow' onClick={() => this.openNewTab(this.state.links[this.state.success])}/>
                </div>                
            </div>
        )
    }
}
const SuccessComponent = withTranslation()(Success);
export default SuccessComponent;