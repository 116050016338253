import React from 'react';
import '../styles/Home.css';
// import BigLogo from './BigLogo.js';
// import Flag from '../assets/EU_flag.png';
import GlowingLogo from '../assets/glowing_logo.png';
import GlowingLogo500 from '../assets/glowing_logo-500.png';
import GlowingLogo300 from '../assets/glowing_logo-300.png';
import { withTranslation } from 'react-i18next';

class Home extends React.Component {
    scroll(to){
        var element = document.querySelector(to);
        element.scrollIntoView({ behavior: 'smooth', block: 'start'});
        document.getElementById('menu').style.display = 'none';
    }

    render(){
        const { t } = this.props;
        return (
            <div id='home'>
                <div id='homeWrapper'>
                    <div id='homeText'>
                        <h2>{t('home.title')}</h2>
                        <button type='button' className='button' onClick={() => {this.scroll('#contact')}}>{t('home.button')}</button>
                    </div>
                    <img src={GlowingLogo} srcSet={`${GlowingLogo300} 300w, ${GlowingLogo500} 500w`} alt='Glowing Logo' id='glowingLogo'/>
                </div>
            </div>
        )
    }
}
const HomeComponent = withTranslation()(Home);
export default HomeComponent;