import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Menu from './components/Menu';
import Home from './components/Home';
import Offer from './components/Offer';
import WaveMobile1 from './components/WaveMobile1';
import WaveMobile2 from './components/WaveMobile2';
import WaveMobile3 from './components/WaveMobile3';
import WaveMobile4 from './components/WaveMobile4';
import Projects from './components/Projects';
import Success from './components/Success';
import About from './components/About';
import Contact from './components/Contact';
import InfoFooter from "./components/InfoFooter";
import Wave1 from './components/wave1';
import Wave2 from './components/wave2';
import Wave3 from './components/wave3';
import Wave4 from './assets/linia4.webp';


class App extends React.Component{

  componentDidMount(){
    this.props.loadLang();
  }

  render(){
    return (
      <div className="App">
        <NavBar changeLang={this.props.changeLng} lang={this.props.lang}/>
        <Menu changeLang={this.props.changeLng} lang={this.props.lang}/>
        <Home />
        <Offer />
        <WaveMobile1 class='mobileWave' />
        <Wave1 class='desktopWave'/>
      
        <Projects />
        <WaveMobile2 class='mobileWave' />
        <Wave2 class='desktopWave'/>

        <Success />
        <WaveMobile3 class='mobileWave' />
        <Wave3 class='desktopWave'/>

        <About />
        <WaveMobile4 class='mobileWave' />
        <img src={Wave4} alt='wave' width='100%' className='desktopWave big'/>

        <Contact />

        <InfoFooter />
      </div>
    );
  }
}

export default App;
