import React from 'react';
import '../styles/Logo.css';

class Wave1 extends React.Component {
    render(){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className={this.props.class} xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 360.06 369.965">
    <defs>
        <linearGradient id="linear-gradient-mobile-4" x1="-.109" x2="1.144" y1=".5" y2=".5" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#26367d"/>
            <stop offset=".5" stopColor="#0a69ae"/>
            <stop offset="1" stopColor="#12b8d4"/>
        </linearGradient>
    </defs>
    <g id="wstega_contact_mobil" transform="translate(.02 -6.032)">
        <path id="Path_1207" fill="url(#linear-gradient-mobile-4)" d="M304.38 12.87c-42.74-10.95-86.03-8.63-121.91 6.54-26.7 11.29-52.25 30.59-78.12 59.01-14.86 16.32-28.53 34.12-41.75 51.32-6.45 8.4-13.12 17.08-19.85 25.48-11.51 14.4-25.85 31.32-42.75 46.8v1.36c17.25-15.68 31.84-32.91 43.54-47.53 6.73-8.41 13.4-17.1 19.86-25.5 13.21-17.19 26.86-34.97 41.69-51.26 25.77-28.31 51.21-47.53 77.77-58.76 35.68-15.09 78.75-17.39 121.27-6.49A266.144 266.144 0 0 1 360 35.49v-1.13a265.984 265.984 0 0 0-55.62-21.49z" className="cls-1" data-name="Path 1207"/>
        <path id="Path_1208" fill="url(#linear-gradient-mobile-4)" d="M339.26 168.63c-18.62 8.29-35.54 24.35-48.94 46.45-10.26 16.93-17.69 35.66-24.88 53.78q-1.65 4.17-3.31 8.32c-7.79 19.41-17.3 41.66-31.62 60.02-15.46 19.82-35.13 32.81-55.4 36.58-19.54 3.63-41.84-.95-62.8-12.91-17.91-10.21-33.05-24.13-50.27-41.88q-3.885-4.005-7.75-8.05c-12.42-12.96-25.27-26.36-39.67-37.57A159.555 159.555 0 0 0 .01 263.2v1.18a160.968 160.968 0 0 1 14 9.78c14.34 11.17 27.16 24.54 39.56 37.47q3.87 4.035 7.76 8.06c17.28 17.81 32.49 31.78 50.49 42.05 21.17 12.08 43.71 16.7 63.48 13.03 20.51-3.81 40.4-16.93 56.01-36.95 14.4-18.46 23.94-40.79 31.75-60.26 1.11-2.77 2.21-5.55 3.31-8.32 7.17-18.08 14.59-36.78 24.81-53.63 13.29-21.92 30.06-37.85 48.49-46.05a85.341 85.341 0 0 1 20.34-6.05v-1.01a84.681 84.681 0 0 0-20.75 6.13z" className="cls-2" data-name="Path 1208"/>
        <path id="Path_1209" fill="url(#linear-gradient-mobile-4)" d="M108.21 165.83C72.67 145.95 36.66 125.8 0 106.64v1.13c36.49 19.09 72.34 39.14 107.72 58.94 80.85 45.24 164.45 92.01 252.28 125.31v-1.07c-87.64-33.28-171.08-79.96-251.79-125.12z" className="cls-3" data-name="Path 1209"/>
        <path id="Path_1210" fill="url(#linear-gradient-mobile-4)" d="M340.92 25.34a255.4 255.4 0 0 0-36.55-12.48 219.872 219.872 0 0 0-28.17-5.3 193.075 193.075 0 0 0-66.98 3.18 164.8 164.8 0 0 0-26.77 8.66 181.293 181.293 0 0 0-34.1 19.22A245.842 245.842 0 0 0 122.39 60c-5.95 5.59-12.03 11.78-18.05 18.4-1.72 1.89-3.48 3.86-5.23 5.86-10.1 11.52-19.5 23.44-27.36 33.58q-4.6 5.94-9.17 11.9c-5.65 7.35-11.49 14.96-17.34 22.34l-2.49 3.12c-9.38 11.72-17.51 21.19-25.57 29.8-5.8 6.23-11.5 11.87-17.18 17.06v1.35c5.92-5.38 11.86-11.24 17.92-17.71 8.08-8.63 16.22-18.12 25.62-29.86.83-1.04 1.66-2.08 2.49-3.13 5.86-7.39 11.7-15 17.35-22.35q4.575-5.955 9.17-11.9c7.85-10.13 17.23-22.03 27.32-33.54 1.75-1.99 3.5-3.96 5.22-5.84 6.01-6.6 12.06-12.78 18-18.35a242.847 242.847 0 0 1 25.86-21.29 180.82 180.82 0 0 1 33.92-19.12 164.015 164.015 0 0 1 26.6-8.61 191.8 191.8 0 0 1 66.63-3.17 219.594 219.594 0 0 1 28.04 5.28 253.318 253.318 0 0 1 36.4 12.43c6.45 2.75 12.96 5.83 19.47 9.19v-1.13q-9.585-4.905-19.09-8.97z" className="cls-1" data-name="Path 1210"/>
        <path id="Path_1211" fill="url(#linear-gradient-mobile-4)" d="M350.52 38.14a318.387 318.387 0 0 0-36.99-13.13 245.645 245.645 0 0 0-28.18-6.25c-23.22-3.71-45.58-3.68-66.48.09a162.3 162.3 0 0 0-26.68 7.18 177.332 177.332 0 0 0-34.42 17.21 229.8 229.8 0 0 0-26.7 20.06c-6.32 5.48-12.64 11.5-18.77 17.88-1.8 1.88-3.63 3.83-5.44 5.81-8.56 9.36-17.21 19.85-28.06 34-4.23 5.52-8.47 11.18-12.58 16.65-4.56 6.08-9.28 12.37-13.99 18.49-.83 1.07-1.65 2.14-2.48 3.21-9.5 12.23-17.67 21.94-25.69 30.58A254.753 254.753 0 0 1 0 212.91v1.29a255.039 255.039 0 0 0 24.8-23.6c8.04-8.66 16.22-18.4 25.74-30.65l2.49-3.21c4.72-6.13 9.44-12.42 14-18.5 4.1-5.47 8.34-11.12 12.57-16.64 10.83-14.13 19.46-24.6 28-33.94 1.8-1.97 3.63-3.92 5.42-5.79 6.11-6.36 12.4-12.36 18.71-17.82a229.722 229.722 0 0 1 26.58-19.97 175.618 175.618 0 0 1 34.22-17.12 160.231 160.231 0 0 1 26.51-7.13c20.78-3.75 43.04-3.78 66.14-.09a245.719 245.719 0 0 1 28.06 6.23 316.357 316.357 0 0 1 36.87 13.09q4.92 2.085 9.87 4.35v-1.1q-4.725-2.16-9.46-4.17z" data-name="Path 1211"/>
        <path id="Path_1212" fill="url(#linear-gradient-mobile-4)" d="M322.68 37.17a280.031 280.031 0 0 0-28.19-7.2c-23.21-4.62-45.41-5.63-65.98-3a161.075 161.075 0 0 0-26.58 5.69 171.979 171.979 0 0 0-34.73 15.2 214.167 214.167 0 0 0-27.44 18.73 250.3 250.3 0 0 0-19.5 17.36c-1.89 1.87-3.79 3.81-5.65 5.75a396.161 396.161 0 0 0-28.76 34.42c-5.01 6.62-9.98 13.41-14.79 19.99-3.86 5.28-7.85 10.73-11.83 16.05-.82 1.1-1.65 2.2-2.48 3.3-9.6 12.7-17.8 22.67-25.8 31.37A215.765 215.765 0 0 1 0 223v1.24a216.7 216.7 0 0 0 31.69-28.72c8.03-8.72 16.25-18.71 25.87-31.44.83-1.1 1.66-2.2 2.48-3.3 3.98-5.32 7.98-10.78 11.84-16.06 4.81-6.57 9.78-13.36 14.78-19.97a394.12 394.12 0 0 1 28.68-34.33c1.85-1.94 3.74-3.87 5.63-5.73a246.467 246.467 0 0 1 19.42-17.29 212.827 212.827 0 0 1 27.31-18.64 171.41 171.41 0 0 1 34.53-15.12 160.167 160.167 0 0 1 26.42-5.66c20.46-2.62 42.55-1.61 65.65 2.99a281.39 281.39 0 0 1 28.09 7.17c11.77 3.65 23.98 8.15 37.34 13.75.09.04.19.08.28.12v-1.09c-13.36-5.61-25.57-10.1-37.33-13.75z" data-name="Path 1212"/>
        <path id="Path_1213" fill="url(#linear-gradient-mobile-4)" d="M331.83 49.32c-9.71-3.27-19.2-6.01-28.2-8.15-23.25-5.54-45.28-7.59-65.47-6.09a159.542 159.542 0 0 0-26.49 4.21 167.03 167.03 0 0 0-35.05 13.19 200.293 200.293 0 0 0-28.18 17.4 221.644 221.644 0 0 0-20.22 16.84c-1.97 1.85-3.94 3.77-5.86 5.7-9.37 9.45-18.73 20.52-29.45 34.84-5.59 7.46-11.08 15.16-16.38 22.6-3.36 4.71-6.84 9.59-10.3 14.34-.82 1.13-1.65 2.26-2.48 3.39a381.738 381.738 0 0 1-25.92 32.15 195.037 195.037 0 0 1-33.95 30.03c-1.28.87-2.57 1.71-3.88 2.54v1.18q2.25-1.41 4.44-2.89a195.968 195.968 0 0 0 34.13-30.19 383.918 383.918 0 0 0 25.99-32.24c.83-1.13 1.66-2.26 2.48-3.39 3.46-4.76 6.94-9.64 10.3-14.35 5.3-7.44 10.79-15.13 16.37-22.58 10.7-14.28 20.03-25.32 29.36-34.73 1.91-1.92 3.87-3.83 5.83-5.68a218.012 218.012 0 0 1 20.13-16.76 199.862 199.862 0 0 1 28.04-17.32 165.791 165.791 0 0 1 34.84-13.11 157.5 157.5 0 0 1 26.32-4.18c20.09-1.5 42.01.54 65.17 6.06 8.97 2.14 18.42 4.87 28.11 8.12 8.82 2.97 18.05 6.4 28.49 10.6v-1.08c-10.31-4.12-19.44-7.51-28.17-10.45z" data-name="Path 1213"/>
        <path id="Path_1214" fill="url(#linear-gradient-mobile-4)" d="M340.98 61.47c-10.06-3.64-19.29-6.62-28.21-9.1-23.67-6.59-44.93-9.59-64.97-9.18a156.948 156.948 0 0 0-61.77 13.91 186.023 186.023 0 0 0-28.92 16.08 197.78 197.78 0 0 0-20.94 16.32c-2.05 1.83-4.09 3.73-6.07 5.65-9.78 9.49-19.36 20.69-30.15 35.26-6.03 8.14-11.89 16.57-17.56 24.71-3 4.31-6.1 8.77-9.18 13.12-.82 1.16-1.64 2.32-2.47 3.47a359.776 359.776 0 0 1-26.04 32.94C33.43 217.12 21.75 227.3 10 234.92c-3.23 2.09-6.58 4.06-10 5.9v1.14c3.61-1.92 7.14-4 10.54-6.2 11.83-7.67 23.58-17.91 34.91-30.43a360.037 360.037 0 0 0 26.11-33.03c.83-1.16 1.65-2.32 2.47-3.48 3.08-4.36 6.19-8.82 9.19-13.13 5.66-8.14 11.51-16.56 17.54-24.69 10.76-14.52 20.31-25.69 30.04-35.14 1.97-1.91 4-3.8 6.04-5.62a198 198 0 0 1 20.83-16.23 186.074 186.074 0 0 1 28.76-15.99 155.9 155.9 0 0 1 61.38-13.82c19.94-.42 41.1 2.58 64.68 9.14 8.9 2.47 18.1 5.44 28.13 9.07 6.46 2.34 12.95 4.84 19.36 7.38v-1.07c-6.28-2.5-12.66-4.95-19-7.25z" data-name="Path 1214"/>
        <path id="Path_1215" fill="url(#linear-gradient-mobile-4)" d="M350.13 73.62c-10.34-4.01-19.57-7.29-28.22-10.04C298.09 56 277 51.99 257.44 51.32a159.34 159.34 0 0 0-26.31 1.24 155.787 155.787 0 0 0-35.68 9.17 175.1 175.1 0 0 0-29.66 14.75 180.317 180.317 0 0 0-21.66 15.79c-2.13 1.81-4.24 3.69-6.28 5.6-10.03 9.37-20.12 21.04-30.85 35.68-6.4 8.72-12.54 17.79-18.48 26.55q-4.14 6.105-8.31 12.19-1.23 1.785-2.47 3.56a350.207 350.207 0 0 1-26.16 33.73c-11.45 12.75-23.38 23-35.48 30.49a134.386 134.386 0 0 1-14.71 7.86c-.47.21-.94.41-1.41.62v1.09c.61-.27 1.22-.53 1.82-.8a133.018 133.018 0 0 0 14.82-7.92c12.18-7.54 24.19-17.86 35.7-30.68a350.116 350.116 0 0 0 26.23-33.82q1.245-1.785 2.47-3.56 4.185-6.075 8.31-12.19c5.94-8.76 12.07-17.81 18.46-26.52 10.69-14.58 20.74-26.21 30.73-35.54 2.02-1.89 4.12-3.76 6.24-5.56a178.308 178.308 0 0 1 21.54-15.7 174.234 174.234 0 0 1 29.49-14.67 155.276 155.276 0 0 1 35.46-9.11 158.379 158.379 0 0 1 26.14-1.23c19.46.67 40.46 4.66 64.2 12.22 8.63 2.74 17.84 6.02 28.16 10.02q5.085 1.98 10.24 4.06v-1.08c-3.29-1.35-6.59-2.67-9.86-3.94z" data-name="Path 1215"/>
        <path id="Path_1216" fill="url(#linear-gradient-mobile-4)" d="M359.28 85.78c-10.89-4.5-19.86-7.99-28.23-10.99-24.38-8.74-44.7-13.62-63.96-15.35a159.994 159.994 0 0 0-26.21-.24 151.966 151.966 0 0 0-36 7.16 163.985 163.985 0 0 0-30.4 13.42 166.451 166.451 0 0 0-22.39 15.27c-2.21 1.79-4.39 3.65-6.48 5.54-10.4 9.37-20.72 21.18-31.55 36.09-6.7 9.23-13.07 18.86-19.22 28.16q-3.795 5.745-7.63 11.47c-.82 1.22-1.64 2.43-2.46 3.65-6.69 9.85-15.72 22.67-26.28 34.51-11.62 13.04-23.81 23.37-36.24 30.73a125.084 125.084 0 0 1-15.09 7.57c-2.33.98-4.72 1.89-7.14 2.74v1.06c2.56-.89 5.07-1.84 7.53-2.87a125.606 125.606 0 0 0 15.21-7.63c12.52-7.41 24.79-17.81 36.48-30.92 10.59-11.88 19.65-24.74 26.36-34.62q1.245-1.815 2.47-3.65c2.56-3.81 5.09-7.64 7.63-11.48 6.15-9.3 12.5-18.91 19.19-28.13 10.79-14.86 21.06-26.61 31.41-35.94 2.08-1.88 4.25-3.73 6.44-5.51a164.077 164.077 0 0 1 52.47-28.52 150.94 150.94 0 0 1 35.76-7.11 160.283 160.283 0 0 1 26.05.24c19.17 1.72 39.42 6.58 63.71 15.29 8.36 2.99 17.31 6.48 28.18 10.97.37.15.74.31 1.11.46v-1.08c-.24-.09-.48-.19-.72-.29z" data-name="Path 1216"/>
        <path id="Path_1217" fill="url(#linear-gradient-mobile-4)" d="M340.19 85.99c-24.7-9.86-44.86-15.72-63.46-18.44a160.363 160.363 0 0 0-26.12-1.73 148.461 148.461 0 0 0-36.31 5.14 154.048 154.048 0 0 0-54.25 26.84c-2.28 1.76-4.53 3.61-6.69 5.49-10.77 9.37-21.31 21.31-32.24 36.51-6.97 9.69-13.51 19.83-19.84 29.64-2.35 3.64-4.69 7.28-7.06 10.9q-1.23 1.875-2.46 3.73c-6.71 10.13-15.77 23.29-26.39 35.3-11.79 13.33-24.24 23.74-37.01 30.96a116.983 116.983 0 0 1-15.47 7.28 124.123 124.123 0 0 1-12.88 4.15v1.03a123.024 123.024 0 0 0 13.23-4.25 116.549 116.549 0 0 0 15.6-7.35c12.86-7.27 25.4-17.76 37.26-31.17 10.66-12.05 19.75-25.25 26.48-35.41.82-1.25 1.65-2.49 2.46-3.74q3.555-5.43 7.07-10.9c6.32-9.8 12.85-19.92 19.81-29.6 10.88-15.14 21.38-27.02 32.09-36.34 2.15-1.87 4.39-3.7 6.65-5.45a153.323 153.323 0 0 1 53.9-26.67 147.542 147.542 0 0 1 36.07-5.11 158.408 158.408 0 0 1 25.96 1.72c18.52 2.71 38.61 8.54 63.23 18.37 6.9 2.75 13.79 5.67 20.19 8.43v-1.09c-6.3-2.68-13.05-5.54-19.82-8.24z" data-name="Path 1217"/>
        <path id="Path_1218" fill="url(#linear-gradient-mobile-4)" d="M349.32 97.2c-25.47-11.21-44.88-17.85-62.95-21.52a162.56 162.56 0 0 0-26.02-3.21 146.793 146.793 0 0 0-68.52 13.9A143.309 143.309 0 0 0 168 100.59c-2.35 1.74-4.68 3.57-6.9 5.44-11.13 9.35-21.9 21.43-32.94 36.93-7.2 10.11-13.88 20.72-20.34 30.98-2.2 3.49-4.39 6.98-6.61 10.45q-1.215 1.92-2.46 3.82c-6.72 10.4-15.81 23.91-26.51 36.08-11.96 13.62-24.67 24.11-37.77 31.19a111.19 111.19 0 0 1-15.86 7A120.076 120.076 0 0 1 0 267.35v1.02a120.379 120.379 0 0 0 18.94-4.95 112.52 112.52 0 0 0 16-7.06c13.21-7.14 26.01-17.7 38.05-31.41 10.74-12.23 19.86-25.77 26.6-36.2q1.23-1.905 2.46-3.83 3.33-5.205 6.62-10.46c6.45-10.25 13.13-20.85 20.31-30.94 10.99-15.43 21.71-27.45 32.77-36.74 2.21-1.86 4.52-3.67 6.85-5.4a142.048 142.048 0 0 1 23.67-14.12 145.9 145.9 0 0 1 68.05-13.8 161.819 161.819 0 0 1 25.87 3.19c17.99 3.66 37.35 10.27 62.75 21.45 3.67 1.61 7.36 3.28 11.08 4.97v-1.1c-3.6-1.61-7.16-3.22-10.7-4.77z" data-name="Path 1218"/>
        <path id="Path_1219" fill="url(#linear-gradient-mobile-4)" d="M358.46 108.41c-19.56-9.42-40.66-18.92-62.45-24.6a165.756 165.756 0 0 0-25.93-4.69 141.818 141.818 0 0 0-36.94 1.12 138.305 138.305 0 0 0-32.63 9.44 135.191 135.191 0 0 0-24.56 13.7c-2.43 1.71-4.82 3.52-7.11 5.38-11.49 9.33-22.49 21.55-33.64 37.34-7.41 10.5-14.21 21.57-20.79 32.26-2.07 3.36-4.13 6.73-6.22 10.07-.81 1.3-1.63 2.61-2.45 3.91-7.15 11.34-15.91 24.58-26.63 36.87-12.13 13.91-25.1 24.48-38.53 31.42a105.574 105.574 0 0 1-16.24 6.71A118.03 118.03 0 0 1 0 272.3v1a118.4 118.4 0 0 0 24.65-5.01 106.148 106.148 0 0 0 16.39-6.78c13.55-7 26.61-17.65 38.83-31.65 10.76-12.34 19.55-25.63 26.72-37 .82-1.3 1.64-2.61 2.45-3.91 2.09-3.35 4.15-6.71 6.22-10.08 6.57-10.68 13.36-21.73 20.75-32.21 11.09-15.72 22.04-27.87 33.45-37.14 2.27-1.85 4.65-3.64 7.06-5.34a134.67 134.67 0 0 1 24.37-13.6 137.5 137.5 0 0 1 32.39-9.37 141.2 141.2 0 0 1 36.69-1.11 165.054 165.054 0 0 1 25.78 4.67c21.7 5.67 42.75 15.14 62.26 24.54.66.32 1.32.64 1.97.95v-1.11c-.49-.25-1.01-.5-1.52-.74z" data-name="Path 1219"/>
        <path id="Path_1220" fill="url(#linear-gradient-mobile-4)" d="M305.66 91.93a170.551 170.551 0 0 0-25.84-6.18 139.9 139.9 0 0 0-37.26-.9 132.157 132.157 0 0 0-33.37 8.11 128.058 128.058 0 0 0-25.28 13.17c-2.5 1.69-4.97 3.48-7.32 5.33-11.84 9.31-23.07 21.66-34.34 37.76-7.61 10.87-14.5 22.36-21.17 33.47q-2.94 4.89-5.89 9.76c-.81 1.33-1.63 2.67-2.45 4-7.17 11.63-15.95 25.19-26.75 37.66-12.31 14.2-25.53 24.85-39.3 31.65a101.981 101.981 0 0 1-16.62 6.43 114.864 114.864 0 0 1-27.46 4.35c-.87.03-1.74.04-2.62.05v1c.89-.02 1.78-.02 2.66-.05a115.865 115.865 0 0 0 27.7-4.39 102.783 102.783 0 0 0 16.79-6.49c13.89-6.86 27.22-17.59 39.61-31.89 10.85-12.52 19.66-26.13 26.84-37.79.82-1.33 1.64-2.67 2.45-4 1.98-3.25 3.93-6.51 5.89-9.77 6.66-11.09 13.55-22.56 21.14-33.41 11.2-16.02 22.37-28.3 34.13-37.55 2.33-1.83 4.78-3.61 7.26-5.29a126.588 126.588 0 0 1 25.08-13.07 131.466 131.466 0 0 1 33.12-8.05 138.717 138.717 0 0 1 36.99.89 170.02 170.02 0 0 1 25.69 6.14c17.28 5.53 34.1 13.34 54.65 23.92v-1.12c-20.37-10.48-37.12-18.24-54.33-23.74z" data-name="Path 1220"/>
        <path id="Path_1221" fill="url(#linear-gradient-mobile-4)" d="M315.3 100.05a175.291 175.291 0 0 0-25.74-7.66 137.666 137.666 0 0 0-37.57-2.91 126.645 126.645 0 0 0-34.11 6.78 121.946 121.946 0 0 0-26 12.65c-2.57 1.66-5.1 3.43-7.53 5.27-12.2 9.28-23.66 21.77-35.03 38.17-7.78 11.23-14.76 23.12-21.51 34.62q-2.79 4.77-5.61 9.51c-.81 1.36-1.62 2.73-2.44 4.09-7.17 11.91-15.97 25.79-26.87 38.44-12.3 14.29-26.16 25.31-40.06 31.88a97.719 97.719 0 0 1-17.01 6.14 112.184 112.184 0 0 1-27.96 3.51h-.3c-2.49 0-5.01-.1-7.55-.25v1c2.54.15 5.06.25 7.55.25h.3A112.265 112.265 0 0 0 36.07 278a99.142 99.142 0 0 0 17.18-6.2c14.04-6.63 28-17.75 40.4-32.14 10.94-12.71 19.77-26.64 26.97-38.58q1.23-2.04 2.45-4.09c1.88-3.16 3.74-6.34 5.61-9.52 6.74-11.49 13.71-23.36 21.47-34.56 11.31-16.32 22.7-28.73 34.82-37.95 2.4-1.83 4.92-3.59 7.46-5.23a121.137 121.137 0 0 1 25.79-12.54 125.873 125.873 0 0 1 33.84-6.72 136.879 136.879 0 0 1 37.3 2.89 172.943 172.943 0 0 1 25.59 7.62c14.75 5.6 29.14 12.89 45.06 21.63v-1.14c-15.77-8.64-30.05-15.86-44.71-21.42z" data-name="Path 1221"/>
        <path id="Path_1222" fill="url(#linear-gradient-mobile-4)" d="M324.94 108.18a180.133 180.133 0 0 0-25.65-9.14 136.684 136.684 0 0 0-37.88-4.92 122.649 122.649 0 0 0-34.85 5.45 117.083 117.083 0 0 0-26.73 12.12c-2.65 1.63-5.25 3.39-7.74 5.22-12.55 9.25-24.24 21.88-35.73 38.59-7.95 11.57-15 23.85-21.82 35.74q-2.67 4.65-5.35 9.29-1.215 2.085-2.44 4.17c-7.18 12.19-16 26.39-26.99 39.23-12.47 14.57-26.59 25.68-40.83 32.12a94.322 94.322 0 0 1-17.39 5.86 108.842 108.842 0 0 1-28.47 2.66c-4.32-.15-8.66-.51-13.07-1.1v1.01a129.515 129.515 0 0 0 13.04 1.09 110.4 110.4 0 0 0 28.73-2.69 94.783 94.783 0 0 0 17.57-5.92c14.37-6.5 28.61-17.69 41.18-32.38 11.04-12.9 19.89-27.14 27.09-39.37q1.23-2.085 2.44-4.18 2.685-4.635 5.36-9.29c6.81-11.87 13.85-24.14 21.78-35.67 11.43-16.62 23.04-29.17 35.5-38.35 2.47-1.82 5.05-3.56 7.67-5.17a115.949 115.949 0 0 1 26.5-12.02 121.444 121.444 0 0 1 34.57-5.4 135.13 135.13 0 0 1 37.61 4.89 179.3 179.3 0 0 1 25.5 9.09 352.31 352.31 0 0 1 35.47 18.55v-1.16a346.074 346.074 0 0 0-35.07-18.32z" data-name="Path 1222"/>
        <path id="Path_1223" fill="url(#linear-gradient-mobile-4)" d="M334.57 116.31a185.189 185.189 0 0 0-25.55-10.62 135.8 135.8 0 0 0-38.2-6.94 118.935 118.935 0 0 0-35.59 4.12 112.566 112.566 0 0 0-27.45 11.6c-2.72 1.61-5.4 3.35-7.95 5.17-12.72 9.09-24.98 22.22-36.43 39-8.11 11.89-15.22 24.56-22.09 36.81-1.71 3.04-3.41 6.08-5.14 9.12q-1.215 2.13-2.44 4.26c-7.18 12.47-16.02 26.98-27.1 40.02C94 263.7 79.62 274.89 65.05 281.19a91.41 91.41 0 0 1-17.77 5.57 106.868 106.868 0 0 1-28.97 1.82 133.251 133.251 0 0 1-18.3-2.57v1.02a133.507 133.507 0 0 0 18.23 2.55 107.623 107.623 0 0 0 29.24-1.84 92.773 92.773 0 0 0 17.97-5.63c14.71-6.36 29.22-17.64 41.96-32.62 11.14-13.1 20-27.65 27.21-40.16q1.23-2.13 2.44-4.27c1.72-3.03 3.43-6.08 5.14-9.12 6.86-12.23 13.96-24.89 22.05-36.74 11.38-16.69 23.55-29.73 36.18-38.75 2.52-1.8 5.17-3.53 7.87-5.12a111.915 111.915 0 0 1 27.21-11.49 117.908 117.908 0 0 1 35.29-4.08 134.949 134.949 0 0 1 37.92 6.89 184.5 184.5 0 0 1 25.41 10.57c8.66 4.34 17.22 9.3 25.87 14.65v-1.18c-8.5-5.26-16.91-10.12-25.43-14.38z" data-name="Path 1223"/>
        <path id="Path_1224" fill="url(#linear-gradient-mobile-4)" d="M344.21 124.44a193.268 193.268 0 0 0-25.46-12.11 135.423 135.423 0 0 0-38.51-8.95 115.683 115.683 0 0 0-36.33 2.79 109.239 109.239 0 0 0-28.17 11.07c-2.8 1.58-5.54 3.3-8.16 5.11-13.07 9.06-25.56 22.33-37.12 39.42-8.26 12.21-15.42 25.25-22.34 37.87q-2.46 4.485-4.94 8.96l-2.43 4.35c-7.18 12.75-16.04 27.57-27.22 40.8-12.81 15.15-27.46 26.42-42.36 32.58a88.852 88.852 0 0 1-18.16 5.29 104.946 104.946 0 0 1-29.48.97A136.811 136.811 0 0 1 0 287.96V289a136.841 136.841 0 0 0 23.43 4.58 106.182 106.182 0 0 0 29.76-.98 89.547 89.547 0 0 0 18.36-5.35c15.05-6.23 29.83-17.59 42.74-32.86 11.24-13.3 20.12-28.17 27.33-40.96q1.23-2.175 2.44-4.35 2.49-4.47 4.94-8.97c6.91-12.59 14.06-25.62 22.29-37.79 11.49-16.99 23.9-30.17 36.86-39.16 2.59-1.79 5.31-3.5 8.08-5.07a108.6 108.6 0 0 1 27.91-10.97 114.927 114.927 0 0 1 36.02-2.76 134.23 134.23 0 0 1 38.23 8.89 192.369 192.369 0 0 1 25.32 12.04c5.46 3.08 10.87 6.4 16.28 9.89v-1.19c-5.24-3.35-10.48-6.57-15.78-9.55z" data-name="Path 1224"/>
        <path id="Path_1225" fill="url(#linear-gradient-mobile-4)" d="M353.85 132.56a200.292 200.292 0 0 0-25.36-13.59A135.623 135.623 0 0 0 289.67 108a113.76 113.76 0 0 0-37.07 1.45 106.428 106.428 0 0 0-28.89 10.54c-2.87 1.56-5.69 3.26-8.36 5.06-13.41 9.03-26.14 22.43-37.82 39.83-8.4 12.52-15.61 25.93-22.58 38.91q-2.37 4.425-4.76 8.82-1.2 2.22-2.43 4.44c-7.19 13.03-16.06 28.16-27.34 41.59-12.98 15.44-27.89 26.79-43.13 32.82a87.071 87.071 0 0 1-18.54 5 103.342 103.342 0 0 1-29.98.13A141.371 141.371 0 0 1 0 289.37v1.06a142.991 142.991 0 0 0 28.62 7.17 104.28 104.28 0 0 0 30.27-.13 88.133 88.133 0 0 0 18.75-5.06c15.39-6.09 30.44-17.54 43.52-33.1 11.34-13.5 20.24-28.68 27.45-41.75q1.23-2.22 2.43-4.44 2.4-4.41 4.76-8.83c6.96-12.95 14.15-26.34 22.53-38.82 11.61-17.29 24.24-30.61 37.55-39.56 2.65-1.78 5.44-3.47 8.28-5.01a105.354 105.354 0 0 1 28.62-10.44 112.638 112.638 0 0 1 36.75-1.44 134.728 134.728 0 0 1 38.54 10.89 198.24 198.24 0 0 1 25.23 13.52c2.24 1.4 4.46 2.86 6.68 4.33v-1.2c-2.02-1.37-4.07-2.71-6.13-4z" data-name="Path 1225"/>
        <path id="Path_1226" fill="url(#linear-gradient-mobile-4)" d="M338.21 125.62a136.311 136.311 0 0 0-39.14-12.98 111.578 111.578 0 0 0-37.81.12 103.633 103.633 0 0 0-29.62 10.02 98.4 98.4 0 0 0-8.57 5.01c-13.76 8.99-26.72 22.54-38.51 40.25-8.54 12.82-15.78 26.6-22.79 39.91q-2.295 4.365-4.6 8.71-1.2 2.265-2.42 4.52c-7.19 13.31-16.07 28.75-27.46 42.38-13.15 15.73-28.32 27.16-43.89 33.05a85.254 85.254 0 0 1-18.92 4.72 102.144 102.144 0 0 1-30.49-.71A147.993 147.993 0 0 1 .01 290.25v1.09a148.4 148.4 0 0 0 33.82 10.27 103.146 103.146 0 0 0 30.79.72 86.223 86.223 0 0 0 19.15-4.78c15.73-5.95 31.05-17.48 44.31-33.35 11.45-13.7 20.36-29.19 27.57-42.54l2.43-4.53q2.31-4.35 4.6-8.72c6.99-13.3 14.23-27.04 22.74-39.83 11.72-17.6 24.59-31.05 38.23-39.97 2.72-1.78 5.57-3.44 8.49-4.96a102.636 102.636 0 0 1 29.33-9.92 110.666 110.666 0 0 1 37.48-.12 135.034 135.034 0 0 1 38.85 12.89 199.4 199.4 0 0 1 22.23 13.03v-1.2a205.881 205.881 0 0 0-21.82-12.71z" data-name="Path 1226"/>
        <path id="Path_1227" fill="url(#linear-gradient-mobile-4)" d="M347.94 132.27a137.312 137.312 0 0 0-39.45-14.99 110.427 110.427 0 0 0-38.55-1.21 101.477 101.477 0 0 0-30.34 9.49 96.585 96.585 0 0 0-8.78 4.95c-14.1 8.96-27.29 22.64-39.21 40.66-8.67 13.12-15.95 27.24-22.98 40.9q-2.22 4.32-4.46 8.63-1.2 2.31-2.42 4.61c-7.19 13.58-16.08 29.33-27.58 43.16-13.32 16.02-28.76 27.53-44.66 33.28a83.675 83.675 0 0 1-19.31 4.44 101.14 101.14 0 0 1-30.99-1.56c-12.29-2.46-24.6-6.85-39.21-14.03v1.11c14.5 7.1 26.76 11.45 39.01 13.91a102.222 102.222 0 0 0 31.3 1.57 85.146 85.146 0 0 0 19.54-4.49c16.07-5.81 31.66-17.43 45.09-33.59 11.55-13.9 20.48-29.71 27.69-43.33q1.215-2.31 2.42-4.62 2.25-4.3 4.46-8.63c7.02-13.63 14.29-27.73 22.93-40.8 11.84-17.9 24.93-31.49 38.91-40.37a95.966 95.966 0 0 1 8.69-4.9 100.635 100.635 0 0 1 30.04-9.4 109.391 109.391 0 0 1 38.2 1.2 136.215 136.215 0 0 1 39.16 14.89c4.1 2.26 8.26 4.8 12.54 7.63v-1.2c-4.09-2.7-8.09-5.13-12.04-7.31z" data-name="Path 1227"/>
        <path id="Path_1228" fill="url(#linear-gradient-mobile-4)" d="M357.67 138.92a138.816 138.816 0 0 0-39.76-17.01 109.432 109.432 0 0 0-39.29-2.54 99.665 99.665 0 0 0-31.06 8.96 93.829 93.829 0 0 0-8.99 4.9c-14.45 8.92-27.87 22.74-39.91 41.08-8.8 13.41-16.11 27.88-23.17 41.88q-2.16 4.275-4.33 8.54c-.8 1.57-1.6 3.13-2.42 4.7-7.19 13.86-16.1 29.92-27.7 43.95-9.4 11.37-24.92 26.42-45.42 33.52a82.476 82.476 0 0 1-19.69 4.15 100.477 100.477 0 0 1-31.5-2.4c-17.31-4-32.72-11.71-44.43-18.21v1.14c11.71 6.47 27.02 14.07 44.21 18.04a101.251 101.251 0 0 0 31.81 2.42 83.4 83.4 0 0 0 19.93-4.2c20.72-7.17 36.38-22.36 45.87-33.83 11.66-14.1 20.6-30.22 27.82-44.13.81-1.57 1.62-3.13 2.42-4.7q2.175-4.26 4.33-8.55c7.05-13.97 14.34-28.42 23.11-41.78 11.95-18.21 25.28-31.93 39.6-40.78a91.875 91.875 0 0 1 8.9-4.84 98.572 98.572 0 0 1 30.75-8.87 108.347 108.347 0 0 1 38.93 2.52 137.377 137.377 0 0 1 39.47 16.89c.95.58 1.9 1.17 2.85 1.78v-1.18c-.78-.49-1.56-.98-2.33-1.45z" data-name="Path 1228"/>
        <path id="Path_1229" fill="url(#linear-gradient-mobile-4)" d="M327.32 126.55a108.842 108.842 0 0 0-40.03-3.87 98.409 98.409 0 0 0-31.78 8.43 93.483 93.483 0 0 0-9.2 4.84c-14.79 8.89-28.45 22.85-40.6 41.49-8.93 13.7-16.26 28.51-23.34 42.84q-2.1 4.245-4.21 8.48c-.8 1.6-1.6 3.19-2.41 4.79-7.18 14.13-16.11 30.5-27.82 44.74-9.52 11.58-25.26 26.83-46.19 33.75a81.455 81.455 0 0 1-20.08 3.87 100.023 100.023 0 0 1-32.01-3.24c-18-4.7-34.09-13.53-46.09-20.7q-1.785-1.07-3.57-2.16v1.17c1.02.62 2.03 1.24 3.06 1.85 12.06 7.2 28.23 16.08 46.35 20.81a101.144 101.144 0 0 0 32.33 3.28 82.015 82.015 0 0 0 20.32-3.92c21.15-7 37.04-22.39 46.65-34.07 11.77-14.31 20.73-30.74 27.94-44.92q1.215-2.385 2.42-4.79 2.115-4.23 4.21-8.48c7.08-14.3 14.39-29.09 23.29-42.74 12.07-18.52 25.62-32.37 40.28-41.18a91.48 91.48 0 0 1 9.1-4.79 97.278 97.278 0 0 1 31.46-8.35 107.918 107.918 0 0 1 39.66 3.84 134.563 134.563 0 0 1 32.95 14.54v-1.17a135.536 135.536 0 0 0-32.69-14.34z" data-name="Path 1229"/>
        <path id="Path_1230" fill="url(#linear-gradient-mobile-4)" d="M336.74 131.19a108.762 108.762 0 0 0-40.77-5.21 96.924 96.924 0 0 0-32.5 7.91 92.339 92.339 0 0 0-9.41 4.79c-15.13 8.85-29.03 22.95-41.3 41.91-9.05 13.98-16.4 29.13-23.5 43.79-1.36 2.81-2.73 5.63-4.1 8.43q-1.2 2.445-2.41 4.87c-7.18 14.41-16.12 31.08-27.94 45.52-9.64 11.78-25.61 27.24-46.96 33.99a80.608 80.608 0 0 1-20.46 3.58 99.426 99.426 0 0 1-32.51-4.09c-17.94-5.21-33.54-14.28-46.44-22.46-2.83-1.79-5.63-3.61-8.44-5.47v1.2c2.63 1.73 5.25 3.44 7.91 5.12 12.96 8.22 28.63 17.33 46.69 22.58a100.862 100.862 0 0 0 32.84 4.13 81.7 81.7 0 0 0 20.72-3.63c21.58-6.82 37.7-22.42 47.43-34.31 11.88-14.52 20.85-31.25 28.06-45.71q1.215-2.43 2.41-4.88 2.07-4.215 4.1-8.43c7.09-14.63 14.43-29.76 23.44-43.68 12.19-18.82 25.97-32.82 40.96-41.58a91.224 91.224 0 0 1 9.3-4.74 95.974 95.974 0 0 1 32.17-7.82 107.791 107.791 0 0 1 40.39 5.16 130.662 130.662 0 0 1 23.57 10.3v-1.15a129.422 129.422 0 0 0-23.25-10.12z" data-name="Path 1230"/>
        <path id="Path_1231" fill="url(#linear-gradient-mobile-4)" d="M346.15 135.82a108.733 108.733 0 0 0-41.5-6.54 97.167 97.167 0 0 0-33.22 7.38 90.305 90.305 0 0 0-9.62 4.73c-15.47 8.81-29.6 23.05-41.99 42.32-9.17 14.26-16.53 29.75-23.66 44.73q-2 4.2-4 8.39-1.2 2.49-2.4 4.96c-7.18 14.68-16.13 31.66-28.06 46.31-9.76 11.98-25.96 27.65-47.72 34.22a79.853 79.853 0 0 1-20.85 3.3 99.848 99.848 0 0 1-33.02-4.93c-18-5.74-33.75-15.47-46.79-24.23-4.43-2.97-8.91-6.1-13.32-9.18v1.22c4.22 2.96 8.52 5.95 12.76 8.79 13.09 8.8 28.92 18.57 47.04 24.35a100.756 100.756 0 0 0 33.35 4.98 81.066 81.066 0 0 0 21.11-3.34c22.01-6.65 38.36-22.46 48.21-34.55 11.99-14.73 20.97-31.77 28.18-46.5q1.215-2.475 2.41-4.97 2.01-4.185 4.01-8.39c7.11-14.95 14.46-30.41 23.59-44.62 12.3-19.13 26.31-33.26 41.65-41.99a88.28 88.28 0 0 1 9.51-4.68 96.356 96.356 0 0 1 32.88-7.3 107.755 107.755 0 0 1 41.12 6.48 125.973 125.973 0 0 1 14.19 6.09v-1.12a132.216 132.216 0 0 0-13.86-5.91z" data-name="Path 1231"/>
        <path id="Path_1232" fill="url(#linear-gradient-mobile-4)" d="M355.56 140.46a108.958 108.958 0 0 0-42.24-7.87 96.844 96.844 0 0 0-33.95 6.85 88.47 88.47 0 0 0-9.82 4.68c-15.82 8.77-30.18 23.15-42.69 42.73-9.29 14.54-16.67 30.36-23.81 45.67q-1.95 4.185-3.91 8.35c-.79 1.68-1.59 3.37-2.4 5.05-7.18 14.95-16.14 32.24-28.18 47.1-9.88 12.19-26.3 28.05-48.49 34.46a79.356 79.356 0 0 1-21.23 3.02 99.951 99.951 0 0 1-33.53-5.78c-18.07-6.27-33.97-16.66-47.13-25.99-4.92-3.48-9.92-7.16-14.76-10.72-1.13-.83-2.28-1.68-3.43-2.52v1.24c.95.7 1.9 1.39 2.84 2.08 4.84 3.56 9.85 7.24 14.78 10.73 13.23 9.38 29.2 19.81 47.38 26.12a100.725 100.725 0 0 0 33.86 5.83 80.1 80.1 0 0 0 21.5-3.05c22.44-6.47 39.02-22.49 48.99-34.79 12.1-14.94 21.1-32.29 28.3-47.29.81-1.68 1.61-3.37 2.4-5.05q1.965-4.17 3.91-8.35c7.13-15.28 14.49-31.07 23.74-45.55 12.42-19.44 26.66-33.71 42.33-42.4a88.3 88.3 0 0 1 9.71-4.63 95.751 95.751 0 0 1 33.59-6.78 108.293 108.293 0 0 1 41.85 7.8c1.61.63 3.21 1.31 4.81 2.01v-1.09c-1.45-.65-2.93-1.27-4.42-1.86z" data-name="Path 1232"/>
        <path id="Path_1233" fill="url(#linear-gradient-mobile-4)" d="M322 135.9a95.791 95.791 0 0 0-34.67 6.32 87.015 87.015 0 0 0-10.03 4.63c-16.16 8.73-30.75 23.25-43.38 43.15-9.4 14.81-16.79 30.96-23.94 46.59q-1.905 4.17-3.82 8.33c-.79 1.71-1.59 3.43-2.4 5.14-7.17 15.22-16.14 32.82-28.29 47.88-9.99 12.39-26.65 28.46-49.26 34.69a77.742 77.742 0 0 1-21.62 2.73 99.759 99.759 0 0 1-34.03-6.62c-18.14-6.79-34.18-17.84-47.48-27.76-4.51-3.36-9.09-6.88-13.52-10.29-3.13-2.41-6.31-4.86-9.54-7.3v1.26c3.02 2.29 6 4.58 8.93 6.84 4.43 3.41 9.02 6.94 13.54 10.3 13.36 9.96 29.47 21.06 47.73 27.89a100.728 100.728 0 0 0 34.37 6.68 79.259 79.259 0 0 0 21.89-2.77c22.86-6.3 39.68-22.53 49.77-35.03 12.22-15.15 21.22-32.81 28.42-48.08.81-1.71 1.61-3.43 2.4-5.14q1.92-4.155 3.83-8.33c7.14-15.59 14.52-31.72 23.88-46.47 12.54-19.75 27.01-34.15 43.01-42.8a87.547 87.547 0 0 1 9.92-4.57 94.825 94.825 0 0 1 34.3-6.25 107.315 107.315 0 0 1 38.01 7.28v-1.07A107.865 107.865 0 0 0 322 135.9z" data-name="Path 1233"/>
        <path id="Path_1234" fill="url(#linear-gradient-mobile-4)" d="M330.67 139.2a95.886 95.886 0 0 0-35.39 5.79 88.088 88.088 0 0 0-10.24 4.57c-16.5 8.69-31.33 23.35-44.08 43.56-9.51 15.08-16.92 31.57-24.08 47.51q-1.86 4.155-3.74 8.3-1.185 2.61-2.39 5.22c-7.17 15.5-16.15 33.4-28.41 48.67-14.29 17.8-32.06 30.21-50.02 34.93a78.22 78.22 0 0 1-22 2.45 100.223 100.223 0 0 1-34.54-7.46c-18.18-7.28-33.73-18.49-47.83-29.52-4.15-3.24-8.36-6.63-12.43-9.9-5.04-4.05-10.22-8.21-15.5-12.28v1.27c5.07 3.92 10.03 7.9 14.88 11.8 4.07 3.27 8.29 6.66 12.44 9.91 14.16 11.07 29.78 22.33 48.08 29.66a101.276 101.276 0 0 0 34.89 7.54 79.754 79.754 0 0 0 22.28-2.48c18.17-4.78 36.12-17.3 50.55-35.27 12.33-15.36 21.35-33.33 28.54-48.88.81-1.74 1.6-3.49 2.4-5.23q1.875-4.14 3.74-8.31c7.15-15.91 14.54-32.37 24.01-47.39 12.65-20.06 27.36-34.6 43.7-43.21a87.739 87.739 0 0 1 10.12-4.52 94.534 94.534 0 0 1 35.01-5.73 105.654 105.654 0 0 1 29.36 5.02v-1.05a107.049 107.049 0 0 0-29.35-4.97z" data-name="Path 1234"/>
        <path id="Path_1235" fill="url(#linear-gradient-mobile-4)" d="M339.34 142.51a95.336 95.336 0 0 0-36.11 5.27 85.313 85.313 0 0 0-10.45 4.52c-16.84 8.65-31.9 23.45-44.77 43.97-9.62 15.35-17.04 32.16-24.2 48.41q-1.83 4.155-3.67 8.3c-.79 1.77-1.58 3.54-2.39 5.31-7.16 15.77-16.16 33.97-28.53 49.46-14.46 18.09-32.5 30.58-50.79 35.16a77.985 77.985 0 0 1-22.39 2.17 100.683 100.683 0 0 1-35.05-8.31c-18.27-7.79-33.95-19.64-48.18-31.29-3.84-3.14-7.73-6.4-11.49-9.55-6.88-5.77-13.99-11.72-21.32-17.39v1.27c7.09 5.51 13.99 11.28 20.67 16.87 3.77 3.16 7.66 6.42 11.5 9.56 14.29 11.69 30.04 23.59 48.42 31.43a101.73 101.73 0 0 0 35.4 8.39 78.866 78.866 0 0 0 22.67-2.19c18.5-4.64 36.73-17.25 51.33-35.51 12.45-15.57 21.47-33.85 28.66-49.67q1.2-2.655 2.39-5.32 1.845-4.14 3.67-8.3c7.15-16.22 14.55-33 24.14-48.29 12.77-20.37 27.7-35.04 44.38-43.61a84.859 84.859 0 0 1 10.33-4.46 94.476 94.476 0 0 1 35.72-5.21 104.412 104.412 0 0 1 20.7 3.09v-1.03a104.265 104.265 0 0 0-20.64-3.05z" data-name="Path 1235"/>
        <path id="Path_1236" fill="url(#linear-gradient-mobile-4)" d="M348.02 145.81a95.309 95.309 0 0 0-36.83 4.74 85.551 85.551 0 0 0-10.66 4.46c-17.18 8.61-32.48 23.54-45.47 44.38-9.73 15.62-17.15 32.75-24.33 49.32q-1.8 4.155-3.6 8.29-1.185 2.7-2.38 5.4c-7.52 16.84-16.19 34.59-28.65 50.24-14.63 18.38-32.94 30.95-51.56 35.4a77.563 77.563 0 0 1-22.77 1.88 101.4 101.4 0 0 1-35.56-9.15c-18.37-8.3-34.18-20.79-48.53-33.05-3.56-3.04-7.17-6.19-10.66-9.23C19.64 292.05 12 285.4 4.1 279.13c-1.4-1.11-2.76-2.17-4.1-3.2v1.26q1.71 1.32 3.48 2.73c7.88 6.26 15.51 12.91 22.88 19.34 3.49 3.04 7.1 6.2 10.67 9.24 14.41 12.31 30.29 24.85 48.77 33.2a102.187 102.187 0 0 0 35.91 9.24 78.89 78.89 0 0 0 23.07-1.91c18.84-4.5 37.35-17.2 52.11-35.75 12.53-15.74 21.24-33.56 28.78-50.46q1.2-2.7 2.39-5.41c1.21-2.76 2.41-5.53 3.6-8.29 7.16-16.54 14.56-33.64 24.26-49.19 12.89-20.68 28.05-35.49 45.07-44.02a85 85 0 0 1 10.53-4.41 94.453 94.453 0 0 1 36.44-4.69 103.1 103.1 0 0 1 12.05 1.53v-1.02a106.457 106.457 0 0 0-11.99-1.51z" data-name="Path 1236"/>
        <path id="Path_1237" fill="url(#linear-gradient-mobile-4)" d="M356.69 149.12a95.137 95.137 0 0 0-37.54 4.21 85.511 85.511 0 0 0-10.87 4.41c-17.52 8.57-33.05 23.64-46.16 44.8-9.84 15.88-17.27 33.34-24.45 50.22-1.18 2.76-2.35 5.53-3.54 8.29q-1.185 2.745-2.38 5.49c-7.51 17.13-16.2 35.17-28.77 51.03-14.79 18.66-33.38 31.32-52.33 35.63a77.871 77.871 0 0 1-23.16 1.6 103.5 103.5 0 0 1-36.07-10c-18.47-8.81-34.41-21.94-48.88-34.82-3.32-2.95-6.62-5.95-9.94-8.95-7.65-6.93-15.55-14.1-23.75-20.81-3.07-2.51-6-4.8-8.86-6.95v1.25c2.66 2.01 5.38 4.15 8.23 6.47 8.18 6.69 16.08 13.85 23.72 20.77 3.31 3 6.62 6 9.94 8.96 14.52 12.93 30.53 26.11 49.11 34.97a104.5 104.5 0 0 0 36.42 10.09 78.555 78.555 0 0 0 23.46-1.62c19.17-4.37 37.96-17.15 52.89-35.99 12.64-15.95 21.36-34.06 28.9-51.25.8-1.83 1.6-3.66 2.38-5.49 1.19-2.76 2.36-5.52 3.54-8.29 7.17-16.85 14.58-34.27 24.38-50.09 13.01-20.99 28.4-35.94 45.75-44.42a84.956 84.956 0 0 1 10.74-4.36 94.3 94.3 0 0 1 37.15-4.16c1.13.1 2.27.22 3.4.35v-1.01c-1.1-.12-2.21-.24-3.31-.33z" data-name="Path 1237"/>
        <path id="Path_1238" fill="url(#linear-gradient-mobile-4)" d="M327.1 156.11a85.97 85.97 0 0 0-11.07 4.35c-17.86 8.53-33.63 23.74-46.86 45.21-9.95 16.15-17.38 33.92-24.56 51.12-1.16 2.77-2.31 5.53-3.48 8.29q-1.185 2.79-2.38 5.57c-7.51 17.42-16.21 35.75-28.89 51.82-14.96 18.95-33.81 31.69-53.09 35.87a77.552 77.552 0 0 1-23.54 1.31 104.242 104.242 0 0 1-36.57-10.84c-18.58-9.31-34.64-23.08-49.23-36.58-3.11-2.87-6.19-5.78-9.29-8.69-7.88-7.42-16.04-15.08-24.52-22.22-4.57-3.85-8.84-7.21-13.04-10.29-.19-.14-.38-.27-.57-.41v1.23c4.18 3.06 8.43 6.41 12.97 10.24 8.46 7.12 16.6 14.78 24.48 22.19 3.1 2.91 6.19 5.82 9.29 8.69 14.64 13.55 30.76 27.38 49.46 36.74a105.092 105.092 0 0 0 36.93 10.94 78.627 78.627 0 0 0 23.85-1.33c19.51-4.23 38.57-17.09 53.67-36.23 12.76-16.16 21.49-34.56 29.03-52.04q1.2-2.79 2.38-5.58c1.17-2.76 2.32-5.52 3.48-8.29 7.17-17.16 14.58-34.9 24.49-50.98 13.13-21.3 28.75-36.39 46.44-44.83a83.971 83.971 0 0 1 10.94-4.3 93.526 93.526 0 0 1 32.6-4.04v-1a94.317 94.317 0 0 0-32.92 4.08z" data-name="Path 1238"/>
        <path id="Path_1239" fill="url(#linear-gradient-mobile-4)" d="M335.05 158.88a84.4 84.4 0 0 0-11.28 4.3c-18.21 8.49-34.2 23.84-47.55 45.62-10.05 16.41-17.49 34.51-24.67 52.01-1.14 2.77-2.27 5.53-3.42 8.29-.78 1.89-1.57 3.77-2.37 5.66-7.5 17.7-16.21 36.32-29.01 52.61-15.13 19.24-34.25 32.06-53.86 36.11a77.673 77.673 0 0 1-23.93 1.03 104.987 104.987 0 0 1-37.08-11.69c-18.7-9.81-34.88-24.23-49.58-38.35-2.92-2.8-5.82-5.63-8.72-8.46-8.1-7.89-16.47-16.05-25.2-23.6-4.56-3.95-8.86-7.42-13.12-10.61-1.75-1.31-3.5-2.55-5.25-3.77v1.22c1.55 1.08 3.1 2.19 4.65 3.35 4.24 3.18 8.52 6.63 13.06 10.57 8.71 7.53 17.07 15.68 25.15 23.57 2.91 2.83 5.81 5.66 8.73 8.46 14.75 14.17 30.99 28.64 49.8 38.51a106.1 106.1 0 0 0 37.44 11.79 78.742 78.742 0 0 0 24.24-1.05c19.84-4.09 39.18-17.04 54.45-36.47 12.87-16.38 21.62-35.07 29.15-52.83q1.2-2.835 2.38-5.67c1.15-2.76 2.28-5.53 3.42-8.29 7.17-17.47 14.59-35.53 24.6-51.87 13.24-21.61 29.1-36.83 47.12-45.24a83.82 83.82 0 0 1 11.15-4.25 92.512 92.512 0 0 1 24.66-3.82v-1a93.826 93.826 0 0 0-24.96 3.87z" data-name="Path 1239"/>
        <path id="Path_1240" fill="url(#linear-gradient-mobile-4)" d="M343 161.66a85.606 85.606 0 0 0-11.49 4.24c-18.29 8.33-34.97 24.25-48.24 46.03-10.16 16.67-17.59 35.09-24.78 52.91-1.12 2.77-2.23 5.53-3.36 8.29-.78 1.92-1.57 3.83-2.37 5.75-7.5 17.98-16.21 36.89-29.13 53.39-15.29 19.53-34.69 32.44-54.63 36.34a77.868 77.868 0 0 1-24.32.75 105.925 105.925 0 0 1-37.59-12.53c-17.78-9.74-32.82-23.08-49.92-40.11q-4.11-4.1-8.2-8.24c-8.29-8.37-16.87-17.02-25.83-24.99-4.58-4.08-8.9-7.65-13.2-10.93-3.31-2.53-6.62-4.86-9.93-7.03v1.2c3.11 2.06 6.22 4.25 9.33 6.63 4.27 3.26 8.57 6.82 13.14 10.88 8.94 7.94 17.5 16.58 25.79 24.94 2.73 2.75 5.46 5.51 8.2 8.24 17.17 17.09 32.26 30.48 50.15 40.28a107.079 107.079 0 0 0 37.95 12.65 78.636 78.636 0 0 0 24.63-.76c20.17-3.95 39.79-16.99 55.23-36.71 12.99-16.59 21.74-35.58 29.27-53.63q1.2-2.88 2.37-5.75c1.13-2.76 2.24-5.53 3.36-8.3 7.18-17.78 14.59-36.16 24.71-52.76 13.17-21.62 29.7-37.4 47.81-45.64a83.546 83.546 0 0 1 11.35-4.19 90.6 90.6 0 0 1 16.72-3.14v-1a92.693 92.693 0 0 0-17.02 3.19z" data-name="Path 1240"/>
        <path id="Path_1241" fill="url(#linear-gradient-mobile-4)" d="M350.96 164.44a85.043 85.043 0 0 0-11.7 4.19c-18.62 8.29-35.54 24.34-48.94 46.45-10.26 16.93-17.69 35.66-24.88 53.78q-1.65 4.17-3.31 8.32c-.78 1.95-1.57 3.89-2.36 5.84-7.49 18.26-16.22 37.47-29.25 54.18-15.46 19.82-35.13 32.81-55.4 36.58a77.771 77.771 0 0 1-24.7.46 107.019 107.019 0 0 1-38.1-13.38c-17.91-10.21-33.05-24.13-50.27-41.88q-3.885-4.005-7.75-8.05c-8.47-8.83-17.22-17.96-26.39-26.32-4.6-4.19-8.94-7.87-13.27-11.25A159.555 159.555 0 0 0 .03 263.19v1.18a160.968 160.968 0 0 1 14 9.78c4.31 3.36 8.63 7.02 13.21 11.19 9.15 8.34 17.89 17.46 26.34 26.27q3.87 4.035 7.76 8.06c17.28 17.81 32.48 31.78 50.5 42.05a108.1 108.1 0 0 0 38.46 13.5 78.89 78.89 0 0 0 25.02-.47c20.51-3.81 40.4-16.93 56-36.95 13.11-16.81 21.87-36.09 29.39-54.42.8-1.95 1.59-3.89 2.37-5.84q1.665-4.155 3.31-8.32c7.17-18.08 14.59-36.78 24.81-53.63 13.29-21.92 30.06-37.85 48.49-46.05a84.329 84.329 0 0 1 11.56-4.14 87.9 87.9 0 0 1 8.78-1.95v-1.01a88.5 88.5 0 0 0-9.07 2z" data-name="Path 1241"/>
        <path id="Path_1242" fill="url(#linear-gradient-mobile-4)" d="M339.26 168.63c-18.62 8.29-35.54 24.35-48.94 46.44-10.26 16.93-17.69 35.66-24.88 53.78q-1.65 4.17-3.31 8.32c-7.79 19.41-17.3 41.66-31.62 60.02-15.46 19.82-35.13 32.81-55.4 36.58-19.54 3.63-41.84-.95-62.8-12.91-17.91-10.21-33.05-24.13-50.27-41.88q-3.885-4.005-7.75-8.05c-12.42-12.96-25.27-26.36-39.67-37.57A159.555 159.555 0 0 0 .01 263.19v1.18a160.968 160.968 0 0 1 14 9.78c14.34 11.17 27.16 24.54 39.56 37.47q3.87 4.035 7.76 8.06c17.28 17.81 32.49 31.78 50.49 42.05 21.17 12.08 43.71 16.7 63.48 13.03 20.51-3.81 40.4-16.93 56.01-36.95 14.4-18.46 23.94-40.79 31.75-60.26 1.11-2.77 2.21-5.55 3.31-8.32 7.17-18.08 14.59-36.78 24.81-53.63 13.29-21.92 30.06-37.85 48.49-46.05a85.341 85.341 0 0 1 20.34-6.05v-1.01a85.133 85.133 0 0 0-20.75 6.14z" className="cls-2" data-name="Path 1242"/>
        <path id="Path_1243" fill="url(#linear-gradient-mobile-4)" d="M339.26 168.63c-18.62 8.29-35.54 24.35-48.94 46.44-10.26 16.93-17.69 35.66-24.88 53.78q-1.65 4.17-3.31 8.32c-7.79 19.41-17.3 41.66-31.62 60.02-15.46 19.82-35.13 32.81-55.4 36.58-19.54 3.63-41.84-.95-62.8-12.91-17.91-10.21-33.05-24.13-50.27-41.88q-3.885-4.005-7.75-8.05c-12.42-12.96-25.27-26.36-39.67-37.57A159.555 159.555 0 0 0 .01 263.19v1.18a160.968 160.968 0 0 1 14 9.78c14.34 11.17 27.16 24.54 39.56 37.47q3.87 4.035 7.76 8.06c17.28 17.81 32.49 31.78 50.49 42.05 21.17 12.08 43.71 16.7 63.48 13.03 20.51-3.81 40.4-16.93 56.01-36.95 14.4-18.46 23.94-40.79 31.75-60.26 1.11-2.77 2.21-5.55 3.31-8.32 7.17-18.08 14.59-36.78 24.81-53.63 13.29-21.92 30.06-37.85 48.49-46.05a85.341 85.341 0 0 1 20.34-6.05v-1.01a85.133 85.133 0 0 0-20.75 6.14z" className="cls-2" data-name="Path 1243"/>
        <path id="Path_1244" fill="url(#linear-gradient-mobile-4)" d="M333.09 169.25c-18.33 7.8-35.12 23.12-48.56 44.28-10.27 16.19-17.82 34.12-25.11 51.46q-1.68 3.99-3.37 7.97c-7.91 18.59-17.54 39.9-31.78 57.42-15.42 18.97-34.89 31.31-54.8 34.76-19.25 3.33-41.25-1.32-61.94-13.09-17.68-10.06-32.71-23.7-49.83-41.09q-3.84-3.9-7.64-7.82c-12.37-12.71-25.16-25.85-39.45-36.86Q5.315 262.2.02 258.75v1.2q4.995 3.285 9.98 7.12c14.23 10.96 27 24.08 39.34 36.76 2.54 2.61 5.09 5.23 7.65 7.82 17.19 17.45 32.28 31.15 50.05 41.26 20.89 11.89 43.13 16.57 62.61 13.2 20.15-3.49 39.83-15.96 55.41-35.11 14.33-17.63 24-39.01 31.93-57.66q1.7-3.975 3.37-7.98c7.28-17.3 14.81-35.2 25.04-51.31 13.33-21 29.96-36.18 48.11-43.9a86.53 86.53 0 0 1 26.52-6.49v-1a88.1 88.1 0 0 0-26.94 6.59z" data-name="Path 1244"/>
        <path id="Path_1245" fill="url(#linear-gradient-mobile-4)" d="M326.92 169.88c-18.3 7.43-34.51 21.6-48.18 42.12-10.28 15.44-17.94 32.57-25.34 49.13q-1.71 3.825-3.42 7.64c-8.39 18.59-17.78 38.14-31.95 54.83-15.39 18.11-34.64 29.81-54.21 32.94-18.97 3.03-40.66-1.68-61.08-13.27-19.4-11.01-35.67-26.58-49.4-40.31-2.52-2.52-5.02-5.05-7.53-7.59-12.32-12.46-25.05-25.34-39.24-36.15-2.19-1.67-4.38-3.25-6.58-4.76v1.22c1.99 1.39 3.98 2.82 5.97 4.33 14.13 10.76 26.84 23.62 39.13 36.06 2.51 2.54 5.01 5.07 7.53 7.59 13.77 13.78 30.1 29.41 49.61 40.47 20.62 11.7 42.54 16.45 61.73 13.38 19.8-3.16 39.27-14.98 54.81-33.28 14.26-16.79 23.68-36.41 32.1-55.06q1.725-3.81 3.43-7.64c7.38-16.52 15.02-33.61 25.26-48.98 13.56-20.36 29.61-34.4 47.72-41.75a87.852 87.852 0 0 1 32.7-6.25v-1a89.016 89.016 0 0 0-33.06 6.33z" data-name="Path 1245"/>
        <path id="Path_1246" fill="url(#linear-gradient-mobile-4)" d="M320.76 170.51c-18 6.94-34.09 20.38-47.8 39.96-10.3 14.7-18.06 31.02-25.56 46.81-1.16 2.43-2.32 4.87-3.48 7.3-8.52 17.74-18.02 36.38-32.12 52.23-15.36 17.26-34.4 28.31-53.61 31.12-18.69 2.73-40.07-2.05-60.22-13.44-19.16-10.85-35.33-26.1-48.97-39.54-2.48-2.44-4.95-4.9-7.41-7.35-12.26-12.21-24.94-24.84-39.03-35.44-.85-.64-1.7-1.26-2.56-1.88v1.24c.65.48 1.3.94 1.96 1.43 14.03 10.56 26.68 23.17 38.92 35.35q3.705 3.69 7.42 7.36c13.69 13.49 29.91 28.79 49.17 39.69 20.34 11.51 41.96 16.33 60.86 13.56 19.45-2.84 38.7-14 54.22-31.44 14.19-15.95 23.72-34.66 32.28-52.46 1.17-2.43 2.32-4.86 3.48-7.3 7.49-15.75 15.23-32.03 25.48-46.66 13.6-19.41 29.52-32.74 47.34-39.6a89.589 89.589 0 0 1 38.89-5.4v-1a90.318 90.318 0 0 0-39.26 5.46z" data-name="Path 1246"/>
        <path id="Path_1247" fill="url(#linear-gradient-mobile-4)" d="M314.59 171.13c-17.71 6.45-33.66 19.17-47.42 37.8-10.31 13.96-18.18 29.47-25.79 44.48-1.18 2.32-2.35 4.64-3.54 6.96-8.65 16.88-18.26 34.62-32.29 49.64-15.32 16.4-34.15 26.81-53.02 29.3-18.4 2.43-39.49-2.41-59.36-13.62-18.89-10.65-34.95-25.58-48.52-38.74-2.44-2.36-4.86-4.74-7.28-7.11C25.58 268.28 13.4 256.38 0 246.21v1.26c13.11 10.03 25.08 21.72 36.67 33.07 2.43 2.38 4.85 4.75 7.29 7.12 13.62 13.2 29.73 28.18 48.72 38.9 20.06 11.32 41.36 16.19 59.99 13.74 19.1-2.52 38.14-13.03 53.62-29.61 14.12-15.12 23.77-32.92 32.45-49.87 1.19-2.32 2.36-4.64 3.54-6.96 7.59-14.97 15.44-30.45 25.7-44.34 13.64-18.47 29.44-31.07 46.96-37.45 13.75-5.01 29.27-6.36 45.07-3.97v-1.01c-15.93-2.38-31.55-1.01-45.42 4.04z" data-name="Path 1247"/>
        <path id="Path_1248" fill="url(#linear-gradient-mobile-4)" d="M356.78 169.05c-16.89-3.28-33.62-2.34-48.36 2.71-17.41 5.97-33.24 17.96-47.04 35.64-10.32 13.21-18.29 27.92-26.01 42.15q-1.8 3.315-3.6 6.62c-8.77 16.02-18.5 32.85-32.46 47.04-15.29 15.55-33.91 25.31-52.43 27.48-18.12 2.12-38.9-2.78-58.5-13.8-18.63-10.48-34.58-25.09-48.08-37.96q-3.585-3.42-7.15-6.87C22.61 261.9 11.75 251.45 0 242.19v1.29c11.47 9.1 22.12 19.34 32.46 29.3 2.38 2.29 4.76 4.59 7.16 6.87 13.54 12.91 29.55 27.57 48.28 38.11 19.79 11.13 40.77 16.07 59.11 13.92 18.74-2.2 37.57-12.06 53.02-27.77 14.04-14.28 23.82-31.18 32.62-47.26q1.815-3.3 3.6-6.63c7.7-14.19 15.65-28.86 25.92-42.01 13.68-17.52 29.35-29.4 46.57-35.3 14.58-5 31.13-5.92 47.85-2.68 1.14.22 2.27.47 3.41.72v-1.03c-1.07-.23-2.14-.46-3.22-.67z" data-name="Path 1248"/>
        <path id="Path_1249" fill="url(#linear-gradient-mobile-4)" d="M349.87 170.39c-16.6-3.36-33.06-2.67-47.61 2-17.11 5.48-32.81 16.75-46.66 33.47-10.33 12.48-18.42 26.39-26.24 39.84-1.22 2.09-2.43 4.18-3.65 6.26-8.9 15.17-18.75 31.09-32.63 44.45-10.8 10.39-28.56 23.28-51.83 25.66-22.87 2.34-43.97-6.31-57.65-13.98-18.37-10.3-34.23-24.59-47.64-37.18q-3.51-3.3-7.01-6.62C19.67 255.5 10.15 246.5 0 238.26v1.28c9.88 8.08 19.19 16.89 28.26 25.48 2.34 2.21 4.67 4.42 7.02 6.62 13.46 12.63 29.37 26.97 47.84 37.32 13.8 7.74 35.11 16.46 58.24 14.1 23.56-2.41 41.51-15.43 52.42-25.93 13.96-13.44 23.86-29.44 32.8-44.66 1.22-2.08 2.44-4.18 3.66-6.27 7.8-13.42 15.87-27.29 26.15-39.7 13.73-16.58 29.27-27.73 46.19-33.16 14.39-4.61 30.67-5.29 47.11-1.97 3.45.7 6.9 1.58 10.33 2.61v-1.04q-5.085-1.515-10.15-2.55z" data-name="Path 1249"/>
        <path id="Path_1250" fill="url(#linear-gradient-mobile-4)" d="M342.95 171.73c-16.3-3.43-32.5-2.99-46.86 1.28-16.81 5-32.38 15.54-46.27 31.31-10.34 11.73-18.54 24.84-26.46 37.51-1.24 1.98-2.47 3.95-3.71 5.92-9.03 14.31-19 29.33-32.8 41.86-10.78 9.78-28.42 21.86-51.24 23.84-22.5 1.96-43.3-6.62-56.78-14.16-18.12-10.12-33.87-24.09-47.21-36.39-2.3-2.12-4.58-4.25-6.87-6.37-8-7.43-16.17-15.01-24.75-22.14v1.3c8.33 6.96 16.28 14.35 24.06 21.58q3.435 3.2 6.88 6.38c13.38 12.34 29.19 26.36 47.4 36.53 13.61 7.6 34.6 16.25 57.36 14.28 23.1-2.01 40.93-14.21 51.83-24.1 13.89-12.61 23.9-27.69 32.97-42.06 1.24-1.97 2.48-3.95 3.72-5.92 7.9-12.64 16.08-25.71 26.36-37.38 13.77-15.63 29.18-26.06 45.81-31.01 14.19-4.22 30.23-4.66 46.37-1.26a117.567 117.567 0 0 1 17.26 5.07v-1.07a118.107 118.107 0 0 0-17.07-5z" data-name="Path 1250"/>
        <path id="Path_1251" fill="url(#linear-gradient-mobile-4)" d="M336.03 173.07c-16.01-3.51-31.95-3.31-46.1.57-16.5 4.53-31.94 14.33-45.89 29.14-10.35 10.99-18.65 23.29-26.68 35.19-1.26 1.86-2.51 3.72-3.78 5.58-9.16 13.46-19.25 27.57-32.96 39.26-10.76 9.17-28.28 20.44-50.65 22.02-22.14 1.57-42.63-6.93-55.93-14.33-17.86-9.95-33.5-23.6-46.77-35.61-2.25-2.03-4.48-4.08-6.72-6.12-6.68-6.1-13.49-12.3-20.55-18.27v1.31c6.82 5.79 13.41 11.79 19.87 17.7q3.36 3.06 6.72 6.13c13.31 12.05 29 25.75 46.95 35.74 13.41 7.47 34.1 16.03 56.48 14.46 22.64-1.6 40.35-12.99 51.23-22.26 13.81-11.78 23.94-25.95 33.14-39.46l3.78-5.58c8.01-11.86 16.29-24.13 26.58-35.06 13.82-14.68 29.1-24.39 45.43-28.87 14-3.84 29.78-4.03 45.63-.56a122.718 122.718 0 0 1 24.18 8.09v-1.09a123.643 123.643 0 0 0-23.96-7.98z" data-name="Path 1251"/>
        <path id="Path_1252" fill="url(#linear-gradient-mobile-4)" d="M329.11 174.42c-15.71-3.59-31.39-3.64-45.35-.15-16.2 4.05-31.51 13.13-45.51 26.98-10.36 10.25-18.78 21.76-26.91 32.88q-1.905 2.61-3.83 5.22c-9.29 12.6-19.51 25.81-33.13 36.67-10.74 8.56-28.15 19.01-50.06 20.21-21.77 1.18-41.97-7.24-55.07-14.51-17.59-9.76-33.14-23.1-46.33-34.83q-3.285-2.925-6.56-5.86c-5.35-4.79-10.79-9.65-16.36-14.41v1.32c5.34 4.57 10.56 9.24 15.69 13.84l6.56 5.87c13.23 11.77 28.83 25.14 46.51 34.95 13.22 7.33 33.59 15.83 55.61 14.64 22.17-1.2 39.77-11.77 50.63-20.42 13.72-10.94 23.98-24.21 33.32-36.86 1.28-1.74 2.55-3.48 3.83-5.22 8.11-11.09 16.5-22.57 26.81-32.76 13.87-13.72 29.03-22.71 45.05-26.72 13.81-3.45 29.33-3.4 44.89.15a128.715 128.715 0 0 1 31.11 11.68v-1.13a129 129 0 0 0-30.9-11.54z" data-name="Path 1252"/>
        <path id="Path_1253" fill="url(#linear-gradient-mobile-4)" d="M322.19 175.76c-15.41-3.66-30.83-3.96-44.6-.86-15.89 3.58-31.07 11.92-45.12 24.81-10.37 9.51-18.89 20.21-27.13 30.56q-1.935 2.445-3.89 4.87c-9.43 11.74-19.77 24.04-33.3 34.08-10.73 7.95-28.02 17.59-49.47 18.39-21.42.81-41.3-7.55-54.21-14.69-17.33-9.58-32.77-22.6-45.89-34.04-2.14-1.86-4.27-3.74-6.4-5.61-4-3.52-8.06-7.07-12.18-10.6v1.32c3.89 3.34 7.73 6.7 11.52 10.03 2.13 1.87 4.26 3.75 6.4 5.61 13.16 11.48 28.65 24.53 46.07 34.17 13.02 7.2 33.09 15.6 54.73 14.82 21.71-.81 39.18-10.55 50.02-18.58 13.63-10.11 24.02-22.47 33.49-34.26 1.3-1.62 2.6-3.25 3.89-4.87 8.22-10.32 16.71-20.99 27.03-30.45 13.92-12.77 28.95-21.04 44.67-24.58 13.62-3.06 28.88-2.77 44.15.86a134.918 134.918 0 0 1 38.04 15.82v-1.17a135.68 135.68 0 0 0-37.82-15.63z" data-name="Path 1253"/>
        <path id="Path_1254" fill="url(#linear-gradient-mobile-4)" d="M357.05 195.42a141.192 141.192 0 0 0-41.77-18.32c-15.12-3.73-30.28-4.28-43.84-1.58-15.57 3.1-30.63 10.72-44.74 22.65-10.38 8.77-19.01 18.67-27.35 28.24q-1.965 2.265-3.95 4.52c-9.56 10.88-20.02 22.28-33.47 31.49-10.71 7.33-27.89 16.16-48.88 16.57-21.05.42-40.63-7.86-53.35-14.87-17.05-9.4-32.4-22.1-45.45-33.26q-3.1-2.655-6.2-5.33c-2.65-2.28-5.33-4.59-8.03-6.88v1.32c2.48 2.11 4.94 4.23 7.38 6.33 2.07 1.78 4.13 3.56 6.21 5.33 13.09 11.2 28.49 23.93 45.62 33.38 12.83 7.07 32.57 15.39 53.86 14.99 21.23-.42 38.6-9.34 49.42-16.75 13.55-9.28 24.06-20.72 33.66-31.65q1.98-2.25 3.95-4.52c8.32-9.54 16.92-19.41 27.25-28.14 13.98-11.81 28.88-19.36 44.29-22.43 13.42-2.68 28.43-2.13 43.41 1.57a140.27 140.27 0 0 1 41.48 18.2c1.18.75 2.33 1.53 3.49 2.31v-1.2c-1.02-.67-1.99-1.33-2.99-1.97z" data-name="Path 1254"/>
        <path id="Path_1255" fill="url(#linear-gradient-mobile-4)" d="M349.49 196.63a144.854 144.854 0 0 0-41.13-18.18c-14.82-3.81-29.72-4.6-43.09-2.29-15.26 2.64-30.18 9.53-44.35 20.48-10.4 8.03-19.13 17.13-27.58 25.93q-2 2.085-4.01 4.16c-9.7 10.03-20.29 20.51-33.65 28.9-15.35 9.63-32.04 14.73-48.29 14.75h-.09c-16.35 0-34.47-5.2-52.4-15.05-16.78-9.21-31.44-21.1-45.02-32.48-2-1.68-4-3.37-6-5.05q-1.935-1.635-3.88-3.27v1.31l3.24 2.73q3 2.535 6 5.05c13.02 10.91 28.32 23.33 45.18 32.59 18.08 9.93 36.36 15.17 52.88 15.17h.09c16.43-.02 33.31-5.18 48.82-14.91 13.45-8.45 24.09-18.98 33.83-29.05q2.01-2.085 4.01-4.17c8.42-8.77 17.13-17.84 27.47-25.83 14.04-10.85 28.82-17.68 43.91-20.29 13.23-2.28 27.99-1.5 42.67 2.27a143.816 143.816 0 0 1 40.84 18.06c3.8 2.4 7.47 4.96 11.05 7.61v-1.25c-3.4-2.5-6.89-4.91-10.5-7.19z" data-name="Path 1255"/>
        <path id="Path_1256" fill="url(#linear-gradient-mobile-4)" d="M341.93 197.83a148.488 148.488 0 0 0-40.48-18.04c-14.52-3.88-29.16-4.92-42.33-3-15.15 2.21-29.53 8.2-43.97 18.31-10.4 7.29-19.25 15.59-27.8 23.62q-2.025 1.905-4.07 3.81c-10.25 9.54-20.55 18.74-33.82 26.31-15.33 8.74-31.81 13.21-47.7 12.94-16.09-.29-33.95-5.55-51.64-15.23-16.51-9.03-31.08-20.61-44.58-31.7-1.85-1.52-3.69-3.04-5.54-4.56v1.3c1.63 1.35 3.27 2.7 4.9 4.04 13.54 11.11 28.15 22.73 44.73 31.8 17.83 9.75 35.85 15.06 52.1 15.35 16.04.3 32.73-4.24 48.22-13.07 13.35-7.62 24.12-17.24 34-26.45q2.04-1.905 4.07-3.81c8.53-8 17.34-16.28 27.69-23.53 14.3-10.03 28.54-15.96 43.54-18.14 13.04-1.9 27.54-.87 41.93 2.98a147.6 147.6 0 0 1 40.21 17.93A197.645 197.645 0 0 1 360 212v-1.27a194.854 194.854 0 0 0-18.07-12.9z" data-name="Path 1256"/>
        <path id="Path_1257" fill="url(#linear-gradient-mobile-4)" d="M334.36 199.03a153.58 153.58 0 0 0-39.84-17.91c-14.22-3.95-28.6-5.24-41.58-3.72-14.82 1.74-29.08 7.02-43.58 16.15-10.42 6.55-19.37 14.05-28.02 21.31-1.37 1.15-2.75 2.3-4.12 3.45-10.4 8.65-20.82 16.97-33.99 23.72-15.54 7.96-31.39 11.71-47.12 11.12-15.8-.59-33.36-5.92-50.78-15.41-16.24-8.84-30.71-20.12-44.14-30.91-.4-.32-.79-.64-1.19-.96v1.28c.19.15.38.3.56.45 13.47 10.82 27.98 22.13 44.29 31.01 17.55 9.56 35.27 14.93 51.22 15.53 15.9.59 31.92-3.19 47.61-11.23 13.26-6.8 23.73-15.16 34.17-23.84 1.38-1.15 2.75-2.3 4.13-3.45 8.63-7.23 17.56-14.71 27.91-21.23 14.37-9.04 28.49-14.28 43.16-16 12.85-1.5 27.09-.23 41.19 3.69a152.388 152.388 0 0 1 39.58 17.79 210.11 210.11 0 0 1 26.16 19.45v-1.31a210.054 210.054 0 0 0-25.62-18.98z" data-name="Path 1257"/>
        <path id="Path_1258" fill="url(#linear-gradient-mobile-4)" d="M326.8 200.23a158.781 158.781 0 0 0-39.19-17.77 113.316 113.316 0 0 0-40.82-4.43c-14.49 1.28-28.62 5.85-43.19 13.98-10.43 5.82-19.48 12.52-28.24 19-1.39 1.03-2.79 2.06-4.18 3.09-10.55 7.75-21.11 15.2-34.16 21.13-15.53 7.05-31.18 10.19-46.53 9.3-15.52-.89-32.78-6.28-49.93-15.59-14.72-7.97-28.08-17.92-40.56-27.65v1.27c12.33 9.61 25.54 19.39 40.07 27.27 17.27 9.37 34.68 14.8 50.35 15.71 15.51.89 31.33-2.27 47-9.39 13.14-5.98 23.74-13.46 34.34-21.24 1.4-1.03 2.79-2.06 4.19-3.09 8.73-6.46 17.77-13.14 28.14-18.93 14.44-8.06 28.44-12.59 42.79-13.86a112.234 112.234 0 0 1 40.46 4.4 157.373 157.373 0 0 1 38.95 17.66 229.6 229.6 0 0 1 33.72 25.85v-1.35a230.459 230.459 0 0 0-33.21-25.36z" data-name="Path 1258"/>
        <path id="Path_1259" fill="url(#linear-gradient-mobile-4)" d="M319.24 201.44a164.5 164.5 0 0 0-38.55-17.63 117.793 117.793 0 0 0-40.07-5.14c-14.15.82-28.15 4.68-42.8 11.81-10.44 5.08-19.61 10.99-28.48 16.7-1.41.91-2.82 1.81-4.23 2.72-10.72 6.85-21.41 13.42-34.34 18.54-15.52 6.14-30.98 8.66-45.95 7.49-15.23-1.2-32.2-6.65-49.07-15.76C23 213.26 11.15 204.89 0 196.51v1.25c11.01 8.26 22.7 16.47 35.29 23.28 16.99 9.18 34.1 14.67 49.47 15.88 15.12 1.19 30.73-1.36 46.39-7.55 13.01-5.15 23.75-11.75 34.51-18.63q2.115-1.35 4.23-2.72c8.84-5.7 17.99-11.58 28.37-16.64 14.52-7.07 28.4-10.9 42.42-11.71a116.778 116.778 0 0 1 39.72 5.1 163.27 163.27 0 0 1 38.31 17.52c15.38 9.45 29.08 20.83 41.28 32.39v-1.37c-12.06-11.38-25.59-22.55-40.75-31.87z" data-name="Path 1259"/>
        <path id="Path_1260" fill="url(#linear-gradient-mobile-4)" d="M311.68 202.64a171.487 171.487 0 0 0-37.9-17.49 123.122 123.122 0 0 0-39.31-5.86c-13.81.37-27.68 3.53-42.4 9.65-10.46 4.35-19.74 9.46-28.72 14.4q-2.13 1.17-4.27 2.34c-10.89 5.95-21.72 11.64-34.51 15.96-15.52 5.22-30.79 7.13-45.37 5.67-14.94-1.5-31.61-7.02-48.21-15.95A281.118 281.118 0 0 1 0 191.53v1.23a279.991 279.991 0 0 0 30.51 19.49c16.71 8.99 33.52 14.54 48.59 16.06 14.72 1.48 30.13-.45 45.79-5.72 12.87-4.34 23.75-10.06 34.67-16.03q2.145-1.17 4.27-2.35c8.95-4.93 18.21-10.03 28.62-14.35 14.61-6.07 28.36-9.2 42.05-9.57a121.864 121.864 0 0 1 38.98 5.81 169.966 169.966 0 0 1 37.68 17.39c18.46 11.26 34.71 25.25 48.84 38.91v-1.38c-14.02-13.49-30.08-27.25-48.32-38.38z" data-name="Path 1260"/>
        <path id="Path_1261" fill="url(#linear-gradient-mobile-4)" d="M304.12 203.84a179.554 179.554 0 0 0-37.26-17.35 129.474 129.474 0 0 0-38.55-6.57c-13.6-.06-27 2.3-42.01 7.48a311.951 311.951 0 0 0-28.96 12.1c-1.43.66-2.87 1.32-4.3 1.97-11.49 5.24-22.03 9.85-34.69 13.37-15.75 4.36-30.4 5.62-44.79 3.85-14.65-1.8-31.03-7.38-47.36-16.13A275.563 275.563 0 0 1 0 186.34v1.2a276.409 276.409 0 0 0 25.73 15.9c16.44 8.8 32.94 14.42 47.71 16.24 14.52 1.78 29.3.51 45.17-3.88 12.74-3.53 23.31-8.17 34.84-13.42 1.44-.66 2.87-1.32 4.31-1.97a309.583 309.583 0 0 1 28.87-12.06c14.9-5.14 28.16-7.46 41.68-7.42a128.429 128.429 0 0 1 38.25 6.52 178.79 178.79 0 0 1 37.05 17.25c21.59 13.07 40.43 29.8 56.4 45.27v-1.39c-15.87-15.32-34.53-31.81-55.89-44.74z" data-name="Path 1261"/>
        <path id="Path_1262" fill="url(#linear-gradient-mobile-4)" d="M358.54 254.4c-18.15-17.2-38.22-35.08-61.99-49.35a189.927 189.927 0 0 0-36.61-17.21 136.686 136.686 0 0 0-37.8-7.28c-13.28-.51-26.51 1.17-41.61 5.31-10.5 2.88-20.01 6.4-29.21 9.81-1.44.53-2.88 1.07-4.33 1.6-11.68 4.29-22.37 8.06-34.87 10.78-15.76 3.41-30.22 4.08-44.21 2.03-14.36-2.11-30.44-7.75-46.5-16.31-7.39-3.94-14.47-8.26-21.42-12.82v1.19c6.79 4.44 13.72 8.66 20.95 12.51 16.16 8.61 32.35 14.29 46.83 16.41 14.11 2.06 28.68 1.4 44.56-2.04 12.56-2.73 23.28-6.51 35-10.82 1.45-.53 2.89-1.07 4.33-1.6 9.18-3.4 18.67-6.91 29.13-9.78 15-4.11 28.13-5.79 41.31-5.28a135.645 135.645 0 0 1 37.51 7.23 187.962 187.962 0 0 1 36.42 17.12c23.68 14.22 43.71 32.06 61.81 49.22.71.68 1.43 1.36 2.14 2.04v-1.38q-.705-.69-1.44-1.38z" data-name="Path 1262"/>
        <path id="Path_1263" fill="url(#linear-gradient-mobile-4)" d="M350.68 253.97c-18.29-16.59-38.42-33.86-61.69-47.72a200.9 200.9 0 0 0-35.97-17.07 146.144 146.144 0 0 0-37.04-7.99c-16.98-1.24-31.65 1.2-41.21 3.15-10.53 2.14-20.17 4.87-29.49 7.51l-4.33 1.22c-10.18 2.86-21.72 6.1-35.05 8.19-15.78 2.46-30.05 2.53-43.63.21-14.27-2.44-29.63-7.99-45.65-16.49-5.67-3.01-11.19-6.25-16.63-9.64v1.18c5.29 3.28 10.65 6.42 16.16 9.34 16.11 8.55 31.57 14.13 45.95 16.59 13.69 2.34 28.07 2.27 43.95-.21 13.38-2.1 24.96-5.35 35.16-8.21l4.33-1.22c9.3-2.63 18.93-5.36 29.42-7.49 9.5-1.94 24.09-4.37 40.94-3.13a145.5 145.5 0 0 1 36.78 7.94 199.874 199.874 0 0 1 35.79 16.98c23.2 13.82 43.28 31.04 61.53 47.6 3.34 3.03 6.69 6.11 9.99 9.15v-1.36c-3.07-2.84-6.19-5.71-9.31-8.53z" data-name="Path 1263"/>
        <path id="Path_1264" fill="url(#linear-gradient-mobile-4)" d="M356.1 265.15c-4.41-3.87-8.82-7.76-13.28-11.62-18.43-15.99-38.63-32.64-61.39-46.08a215.353 215.353 0 0 0-35.32-16.93 157.5 157.5 0 0 0-36.28-8.7 166.124 166.124 0 0 0-40.82.99c-10.56 1.41-20.33 3.34-29.78 5.21l-4.31.85a351.505 351.505 0 0 1-35.23 5.6c-16.02 1.52-29.7 1.01-43.05-1.61-13.98-2.75-29.05-8.36-44.8-16.67-4.03-2.13-7.96-4.35-11.85-6.65v1.16c3.74 2.2 7.51 4.33 11.38 6.37 15.83 8.36 31 14 45.07 16.77 13.45 2.64 27.22 3.16 43.34 1.63a355.581 355.581 0 0 0 35.33-5.61l4.31-.85c9.43-1.87 19.19-3.8 29.72-5.2a165.749 165.749 0 0 1 40.58-.98 157 157 0 0 1 36.04 8.65 214.579 214.579 0 0 1 35.16 16.85c22.69 13.4 42.84 30.01 61.24 45.98 4.46 3.87 8.87 7.75 13.28 11.62 1.51 1.33 3.04 2.67 4.56 4.01v-1.33c-1.3-1.17-2.61-2.32-3.9-3.46z" data-name="Path 1264"/>
        <path id="Path_1265" fill="url(#linear-gradient-mobile-4)" d="M347.2 263.3q-6.09-5.1-12.24-10.21c-21.5-17.8-40.12-32.17-61.09-44.45a232.086 232.086 0 0 0-34.68-16.79 172.831 172.831 0 0 0-35.52-9.42 191.477 191.477 0 0 0-40.42-1.17c-10.64.67-20.57 1.81-30.18 2.92l-4.31.49c-11.07 1.26-22.52 2.56-35.29 2.99-16.05.54-29.55-.55-42.47-3.43-13.68-3.05-28.47-8.72-43.94-16.85-2.38-1.25-4.73-2.54-7.06-3.85v1.15q3.27 1.83 6.6 3.59c15.55 8.17 30.42 13.87 44.19 16.94 13.01 2.9 26.58 4 42.72 3.46 12.8-.44 24.27-1.74 35.37-3l4.31-.49c9.59-1.1 19.51-2.24 30.12-2.91a190.814 190.814 0 0 1 40.21 1.17 171.578 171.578 0 0 1 35.31 9.36 231.933 231.933 0 0 1 34.53 16.71c20.91 12.24 39.49 26.58 60.95 44.35q6.165 5.1 12.24 10.2c4.43 3.7 8.91 7.45 13.44 11.19v-1.3c-4.3-3.55-8.57-7.12-12.79-10.65z" data-name="Path 1265"/>
        <path id="Path_1266" fill="url(#linear-gradient-mobile-4)" d="M338.02 261.32q-5.445-4.32-10.93-8.66c-17.52-13.82-38.11-29.66-60.79-42.81a254.253 254.253 0 0 0-34.03-16.64 188.8 188.8 0 0 0-34.76-10.13 223.308 223.308 0 0 0-40.02-3.33c-10.73-.08-20.85.27-30.64.6l-5 .17c-10.91.36-22.2.72-34.58.38-10.14-.29-25.51-1.15-41.9-5.26-13.38-3.36-27.88-9.09-43.09-17.03-.76-.4-1.52-.81-2.28-1.22v1.13c.61.32 1.21.65 1.82.97 15.28 7.98 29.85 13.74 43.31 17.12 16.48 4.13 31.93 5 42.11 5.29 12.42.35 23.72-.02 34.64-.38l5.01-.17c9.78-.33 19.88-.67 30.6-.6a223.156 223.156 0 0 1 39.85 3.32 187.813 187.813 0 0 1 34.58 10.07 254.15 254.15 0 0 1 33.9 16.58c22.62 13.11 43.17 28.93 60.67 42.73q5.49 4.32 10.93 8.66c7.4 5.88 14.93 11.86 22.6 17.75v-1.26c-7.48-5.74-14.8-11.56-22-17.28z" data-name="Path 1266"/>
        <path id="Path_1267" fill="url(#linear-gradient-mobile-4)" d="M328.43 259.15q-4.59-3.45-9.2-6.92c-18.56-13.92-38.52-28.56-60.48-41.17a285.3 285.3 0 0 0-33.38-16.51 212.7 212.7 0 0 0-34-10.83 272.5 272.5 0 0 0-39.62-5.49c-10.88-.83-21.23-1.31-31.24-1.77l-5.86-.27c-10.66-.49-21.68-1.01-33.58-2.08-11.04-1.01-25.7-2.72-41.32-7.08A220.014 220.014 0 0 1 .02 151.08v1.12a220.565 220.565 0 0 0 39.46 15.79c15.69 4.39 30.42 6.11 41.5 7.12 11.93 1.08 22.96 1.59 33.63 2.09l5.86.27c10 .46 20.35.93 31.21 1.76a272.771 272.771 0 0 1 39.48 5.47A212.731 212.731 0 0 1 225 195.48a284.122 284.122 0 0 1 33.27 16.45c21.92 12.58 41.85 27.21 60.38 41.11q4.62 3.465 9.2 6.92c10.47 7.88 21.18 15.94 32.17 23.74v-1.22c-10.8-7.68-21.31-15.59-31.59-23.33z" data-name="Path 1267"/>
        <path id="Path_1268" fill="url(#linear-gradient-mobile-4)" d="M318.27 256.71l-6.9-4.92c-19.66-13.99-39.06-27.54-60.18-39.54a324.071 324.071 0 0 0-32.74-16.36 246.919 246.919 0 0 0-33.24-11.54 341.344 341.344 0 0 0-39.23-7.65c-11.18-1.63-21.92-2.96-32.31-4.24l-6.73-.83c-10.25-1.27-20.84-2.57-32.11-4.31a280.168 280.168 0 0 1-40.74-8.91 246.656 246.656 0 0 1-34.1-13.76v1.11a244.959 244.959 0 0 0 33.8 13.61 282.043 282.043 0 0 0 40.88 8.95c11.28 1.73 21.88 3.04 32.14 4.31l6.73.83c10.38 1.28 21.12 2.61 32.28 4.24a340.062 340.062 0 0 1 39.11 7.62 245.035 245.035 0 0 1 33.11 11.5 323.03 323.03 0 0 1 32.63 16.31c21.08 11.98 40.45 25.51 60.1 39.48l6.9 4.92c13.7 9.77 27.8 19.8 42.31 29.25v-1.19c-14.28-9.34-28.19-19.24-41.71-28.88z" data-name="Path 1268"/>
        <path id="Path_1269" fill="url(#linear-gradient-mobile-4)" d="M307.2 253.85l-3.7-2.49c-21.65-14.57-39.8-26.61-59.88-37.9-11.03-6.2-21.83-11.66-32.09-16.22a295.538 295.538 0 0 0-32.48-12.25c-14.11-4.37-27.44-7.35-38.83-9.8-11.75-2.53-23.13-4.8-34.14-7l-7.63-1.52c-9.57-1.9-19.47-3.87-29.83-6.13a358.942 358.942 0 0 1-40.16-10.74A281.946 281.946 0 0 1 0 138.14v1.1a281.569 281.569 0 0 0 28.14 11.5 357.759 357.759 0 0 0 40.27 10.77c10.36 2.25 20.27 4.22 29.85 6.13l7.63 1.52c11.01 2.2 22.39 4.47 34.13 7 11.37 2.45 24.68 5.42 38.75 9.78a293.55 293.55 0 0 1 32.37 12.21c10.24 4.55 21 9.99 32.01 16.18 20.04 11.27 38.18 23.3 59.81 37.86l3.7 2.49c17.22 11.59 35.02 23.56 53.36 34.47v-1.17c-18.15-10.8-35.77-22.65-52.82-34.13z" data-name="Path 1269"/>
        <path id="Path_1270" fill="url(#linear-gradient-mobile-4)" d="M354.17 286.52c-20.35-11.35-39.76-23.67-58.53-35.59l-1.94-1.23c-18.54-11.77-37.71-23.95-57.63-35.03-10.87-6.05-21.45-11.46-31.44-16.08-10.51-4.86-21.19-9.22-31.73-12.96-13.45-4.77-26.36-8.52-38.44-11.95-12.06-3.43-23.32-6.53-35.75-9.93q-5.145-1.41-10.34-2.82c-8.38-2.28-17.05-4.64-25.96-7.15-11.76-3.31-25.48-7.29-39.58-12.57-7.2-2.71-14.84-5.93-22.83-9.61v1.1c7.86 3.61 15.39 6.77 22.47 9.42 14.14 5.29 27.88 9.28 39.66 12.6 8.92 2.51 17.58 4.87 25.97 7.16q5.205 1.41 10.34 2.82c12.42 3.4 23.69 6.5 35.74 9.93s24.95 7.18 38.38 11.94c10.51 3.73 21.16 8.08 31.64 12.92 9.97 4.61 20.52 10.01 31.38 16.05 19.89 11.07 39.05 23.24 57.58 35l1.94 1.23c18.78 11.92 38.2 24.25 58.58 35.62 2.13 1.19 4.23 2.34 6.32 3.47v-1.14q-2.88-1.56-5.83-3.2z" data-name="Path 1270"/>
        <path id="Path_1271" fill="url(#linear-gradient-mobile-4)" d="M346.56 283.98c-20.53-10.76-40.3-22.44-58.8-33.49l-8.25-4.94c-16.52-9.89-33.6-20.12-51.02-29.69-10.89-5.99-20.97-11.21-30.79-15.94-10.24-4.93-20.66-9.53-30.97-13.67-12.71-5.1-24.75-9.41-38.04-14.11-10.62-3.76-21.73-7.64-36.03-12.6q-9.12-3.165-18.37-6.33c-5.95-2.05-11.97-4.12-18.11-6.24-13.07-4.53-25.89-9.04-39-14.4q-8.28-3.39-17.19-7.48v1.1q8.71 3.99 16.81 7.31c13.14 5.37 25.97 9.89 39.05 14.42 6.14 2.13 12.16 4.2 18.11 6.24 6.18 2.13 12.28 4.22 18.36 6.33 14.29 4.95 25.4 8.84 36.02 12.59 13.28 4.7 25.31 9 38 14.09 10.29 4.13 20.69 8.72 30.91 13.64 9.81 4.72 19.87 9.93 30.75 15.92 17.4 9.57 34.47 19.79 50.99 29.67l8.25 4.94c18.5 11.05 38.29 22.74 58.84 33.51 4.76 2.49 9.38 4.84 13.9 7.07v-1.12c-4.36-2.14-8.82-4.41-13.42-6.82z" data-name="Path 1271"/>
        <path id="Path_1272" fill="url(#linear-gradient-mobile-4)" d="M338.96 281.44c-21.38-10.49-41.85-21.75-59.06-31.38q-9.555-5.355-19.15-10.78c-12.97-7.33-26.39-14.91-39.82-22.21-10.83-5.89-20.69-11.05-30.15-15.8-9.98-5-20.14-9.84-30.21-14.37-12.83-5.78-25.4-11.09-37.65-16.26-13.07-5.52-24.95-10.51-36.31-15.26-11.46-4.79-22.94-9.53-36.65-15.17l-1.73-.71c-11.9-4.9-24.2-9.96-36.69-15.52q-5.58-2.49-11.55-5.28v1.1q5.76 2.685 11.15 5.09c12.5 5.56 24.81 10.63 36.71 15.53l1.73.71c13.71 5.64 25.19 10.38 36.64 15.17 11.36 4.75 23.24 9.74 36.4 15.3 12.15 5.13 24.72 10.44 37.54 16.21 10.06 4.53 20.21 9.36 30.17 14.35 9.45 4.74 19.3 9.9 30.12 15.78 13.42 7.3 26.84 14.87 39.81 22.2q9.6 5.43 19.15 10.79c17.22 9.64 37.71 20.91 59.11 31.41 7.43 3.65 14.55 6.99 21.48 10.1v-1.1c-6.79-3.05-13.76-6.33-21.04-9.9z" data-name="Path 1272"/>
        <path id="Path_1273" fill="url(#linear-gradient-mobile-4)" d="M331.36 278.91c-17.99-8.23-36.84-17.53-59.32-29.28-14.55-7.61-29.34-15.57-43.64-23.27q-7.5-4.035-15.03-8.08c-11.45-6.15-20.82-11.12-29.5-15.66-9.83-5.14-19.74-10.21-29.45-15.08-11.52-5.78-23.03-11.43-34.16-16.89l-3.1-1.52c-12.27-6.03-24.41-11.99-36.59-17.92-12.57-6.13-24.61-11.94-36.82-17.77q-6.18-2.955-12.44-5.92c-8.29-3.94-16.86-8.02-25.39-12.14q-2.955-1.425-5.92-2.87v1.11q2.745 1.335 5.48 2.66c8.54 4.13 17.11 8.2 25.4 12.15q6.255 2.97 12.44 5.92c12.21 5.83 24.25 11.64 36.81 17.77 12.18 5.94 24.32 11.9 36.59 17.92l3.1 1.52c11.13 5.46 22.63 11.11 34.15 16.89 9.7 4.86 19.61 9.93 29.44 15.07 8.67 4.53 18.04 9.51 29.49 15.65q7.53 4.035 15.02 8.08c14.3 7.71 29.09 15.67 43.65 23.28 22.5 11.76 41.36 21.07 59.37 29.3 10.09 4.62 19.68 8.78 29.06 12.63v-1.08c-9.25-3.81-18.7-7.92-28.64-12.47z" data-name="Path 1273"/>
        <path id="Path_1274" fill="url(#linear-gradient-mobile-4)" d="M323.75 276.37c-19.23-8.15-38.72-17.04-59.58-27.18-18.31-8.9-37.4-18.62-58.36-29.72-9.15-4.85-18.59-9.92-28.85-15.51-8.78-4.78-18.17-9.94-28.7-15.78-12.09-6.7-23.85-13.28-36.88-20.57l-3.17-1.78q-16.845-9.42-33.7-18.81c-14.06-7.82-25.81-14.29-36.99-20.37-13.36-7.27-25.54-13.77-37.24-19.9-.09-.05-.19-.1-.28-.15v1.13c11.64 6.09 23.76 12.57 37.05 19.79 11.18 6.08 22.93 12.55 36.98 20.36q16.86 9.375 33.7 18.81l3.17 1.78c13.03 7.29 24.79 13.87 36.88 20.57 10.53 5.84 19.92 11 28.7 15.79 10.27 5.59 19.71 10.67 28.86 15.52 20.97 11.11 40.07 20.84 58.39 29.74 20.88 10.14 40.38 19.04 59.63 27.2 12.35 5.23 24.66 10.17 36.64 14.72v-1.07c-11.86-4.5-24.04-9.39-36.25-14.57z" className="cls-3" data-name="Path 1274"/>
        <path id="Path_1275" fill="url(#linear-gradient-mobile-4)" d="M323.75 276.37c-19.23-8.15-38.72-17.04-59.58-27.18-18.31-8.9-37.4-18.62-58.36-29.72-9.15-4.85-18.59-9.92-28.85-15.51-8.78-4.78-18.17-9.94-28.7-15.78-12.09-6.7-23.85-13.28-36.88-20.57l-3.17-1.78q-16.845-9.42-33.7-18.81c-14.06-7.82-25.81-14.29-36.99-20.37-13.36-7.27-25.54-13.77-37.24-19.9-.09-.05-.19-.1-.28-.15v1.13c11.64 6.09 23.76 12.57 37.05 19.79 11.18 6.08 22.93 12.55 36.98 20.36q16.86 9.375 33.7 18.81l3.17 1.78c13.03 7.29 24.79 13.87 36.88 20.57 10.53 5.84 19.92 11 28.7 15.79 10.27 5.59 19.71 10.67 28.86 15.52 20.97 11.11 40.07 20.84 58.39 29.74 20.88 10.14 40.38 19.04 59.63 27.2 12.35 5.23 24.66 10.17 36.64 14.72v-1.07c-11.86-4.5-24.04-9.39-36.25-14.57z" className="cls-3" data-name="Path 1275"/>
    </g>
</svg>

        )
    }
}

export default Wave1;