import React from 'react';
import '../styles/About.css';
import AboutCard from './AboutCard';
import Zuchniak from '../assets/zuchniak-150.jpg';
import Jaglarz from '../assets/jaglarz-150.jpg';
import Szelag from '../assets/szelag-150.jpg';
import { withTranslation } from 'react-i18next';

class About extends React.Component {
    render(){
        const { t } = this.props;
        return (
            <div id='about'>
                <h1>{t('about.title')}</h1>
                <p id='about-description'>{t('about.description')}</p>
                <div id='aboutCards'>
                    <AboutCard img={Zuchniak} name='Konrad Zuchniak' position='Data Scientist & Co-founder' description={t('about.1.description')}/>
                    <AboutCard img={Szelag} name='Piotr Szeląg' position='CEO & Co-founder' description={t('about.2.description')}/>
                    <AboutCard img={Jaglarz} name='Piotr Jaglarz' position='Network Software Engineer & Co-founder' description={t('about.3.description')}/>
                </div> 
            </div>
        )
    }
}
const AboutComponent = withTranslation()(About);
export default AboutComponent;